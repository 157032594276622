import { MoreHorizontal, Plus } from "react-feather";
import React, { useContext, useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import {
	useCanManageValidations,
	useFetchData,
	useQueryData,
} from "../../../hooks";
import Button from "../../layout/Button";
import DropdownMenuBeta from "../../../components/layout/DropdownMenuBeta";
import ReportWrapperBeta from "../ReportWrapperBeta";
import gql from "graphql-tag";
import { money } from "../../../helpers/format";
import { format } from "../../../helpers";
import ValidationWizard from "../../wizards/validation-wizard";
import {
	VALIDATION_TYPE_OPTIONS,
	VALIDATION_WIZARD_TYPE,
} from "../../../helpers/constants";
import { AppContext } from "../../../context/app-context";
import { getLeaseParksForOrganization } from "../../../api/installation";

const defaultSorting = [
	{
		id: "CreatedOn",
		desc: false,
	},
];

export const useGetValidationQRCodes = (siteIds, pageInfo, cursor, skip) => {
	const {
		data,
		loading: isLoading,
		isError,
		refetch,
	} = useQueryData(
		gql`
			query GetValidationQRCodesForOrganization(
				$siteIds: [Int!]
				$page: PageInfo!
				$cursor: Int
			) {
				getValidationQRCodesForOrganization(
					siteIds: $siteIds
					page: $page
					cursor: $cursor
				) {
					previousEvent
					trueLength
					qrCodes {
						QRCodeID
						ValidationType
						ValidationValue
						IsSelfValidation
						Location
						CreatedOn
						Sites {
							SiteID
							Name
						}
						Rates {
							RateID
							Name
							SiteID
						}
						LeaseParks {
							LeaseParkID
							Name
						}
					}
				}
			}
		`,
		{
			siteIds: siteIds || [],
			page: pageInfo,
			cursor: cursor,
		},
		skip
	);

	const validationQRCodes = data
		? data.getValidationQRCodesForOrganization
		: null;

	return { validationQRCodes, isLoading, isError, refetch };
};

export default function ValidationQRCodes(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			search: "",
		},
		qrCode: "",
		wizardOpen: false,
		submitting: false,
	});

	const {
		state: { availableSites },
	} = useContext(AppContext);

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const { data: leaseParks } = useFetchData(
		[],
		getLeaseParksForOrganization,
		[organizationId],
		[organizationId]
	);

	const validationSites = availableSites
		.filter((site) => site.HasValidation)
		.map((site) => ({
			id: site.SiteID,
			name: site.Name,
		}));

	const spaces = validationSites.map((site) => ({
		SiteID: site.id,
		Spaces: leaseParks
			.filter((lp) => lp.sites.some((s) => s.SiteID === site.id))
			.map((lp) => ({ LeaseParkID: lp.LeaseParkID, Name: lp.Name })),
	}));

	const [paginate, setPaginate] = useState(paginatedState);
	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = (mode, qrCode) => {
		setState((_state) => ({
			..._state,
			qrCode,
			wizardOpen: true,
			wizardMode: mode,
		}));
	};

	const canEditSettings = useCanManageValidations();

	const getValue = (qrCode) => {
		if (qrCode.ValidationType === "Percentage") {
			return `${qrCode.ValidationValue}%`;
		}

		if (qrCode.ValidationType === "Rate") {
			const rate = qrCode.Rates.find(
				(r) => r.SiteID === qrCode.Sites[0].SiteID
			);

			if (rate) {
				return rate.Name;
			}
		}

		return money(qrCode.ValidationValue);
	};

	const columns = [
		{
			id: "Location",
			Header: "Location",
			accessor: (d) => d.Location,
			highlightCell: (cellProps) => cellProps.row.original.Location || "",
		},
		{
			id: "IsSelfValidation",
			Header: "Self Validation",
			accessor: (d) => (d.IsSelfValidation ? "Yes" : "No"),
		},
		{
			id: "Type",
			Header: "Type",
			accessor: (d) => d.ValidationType,
		},
		{
			id: "Value",
			Header: "Value",
			accessor: (d) => getValue(d),
			disableSortBy: true,
		},
		{
			id: "Sites",
			Header: "Site(s)",
			accessor: (d) => d.Sites?.map((site) => site.Name).join(", "),
			disableSortBy: true,
		},
		{
			id: "Spaces",
			Header: "Space(s)",
			accessor: (d) => d.LeaseParks?.map((lp) => lp.Name).join(", "),
			disableSortBy: true,
		},
		{
			id: "CreatedOn",
			Header: "CreatedOn",
			accessor: (d) => d.CreatedOn,
			Cell: (cellProps) =>
				format.localDate(cellProps.row.original.CreatedOn, "X", true),
		},
	];

	const canManageQRCodes = (_props) => [
		...columns,
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => {
				return (
					<DropdownMenuBeta
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="download"
								onClick={() =>
									_props.openWizard("download", cellProps.row.original)
								}
							>
								Download
							</div>,
							<div
								key="update"
								onClick={() =>
									_props.openWizard("update", cellProps.row.original)
								}
							>
								Edit
							</div>,
							<div
								key="delete"
								onClick={() =>
									_props.openWizard("delete", cellProps.row.original)
								}
							>
								Delete
							</div>,
						]}
					/>
				);
			},
			resizable: false,
			width: 50,
		},
	];

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;
	const {
		validationQRCodes: getValidationQRCodesForOrganization,
		isLoading,
		refetch,
	} = useGetValidationQRCodes(
		state.options.sites,
		{
			pageOffset: paginate.pageOffset,
			pageSize: paginate.pageSize,
			sortBy: paginate.sortBy,
			sortOrder: paginate.sortOrder,
			searchTokens: paginate.searchTokens,
			eventType: paginate.eventType,
			sortedVal: paginate.cursor.sortedVal,
		},
		paginate.cursor.cursor,
		skipQuery
	);

	let trueLength = getValidationQRCodesForOrganization?.trueLength || 0;
	let qrCodes = [];

	if (getValidationQRCodesForOrganization?.qrCodes?.length > 0) {
		qrCodes = getValidationQRCodesForOrganization.qrCodes.map((qrCode) => ({
			...qrCode,
			sites: qrCode.Sites?.map((s) => {
				const _site = {
					id: s.SiteID,
					name: s.Name,
				};

				const rate = qrCode.Rates.find((r) => r.SiteID === s.SiteID);

				if (rate) {
					Object.assign(_site, { rateId: rate.RateID });
				}

				return _site;
			}),
		}));
	}

	async function refetchData() {
		await refetch();
		setState((_state) => ({ ..._state, submitting: false }));
	}

	if (state.wizardOpen) {
		return (
			<ValidationWizard
				type={VALIDATION_WIZARD_TYPE.QR_CODE}
				mode={state.wizardMode}
				organizationId={organizationId}
				validationTypeOptions={VALIDATION_TYPE_OPTIONS}
				rateData={qrCodes.flatMap((qrCode) => qrCode.Rates)}
				availableSites={validationSites}
				availableSpaces={spaces}
				close={() => {
					setState((_state) => ({
						..._state,
						wizardOpen: false,
					}));
					refetchData();
				}}
				qrCode={state.qrCode}
			/>
		);
	}

	return (
		<ReportWrapperBeta
			{...props}
			title="QR Codes"
			data={qrCodes}
			columns={canEditSettings ? canManageQRCodes({ openWizard }) : columns}
			defaultSortBy={defaultSorting}
			updateOptions={updateOptions}
			loading={state.submitting}
			rightActions={
				canEditSettings ? (
					<>
						<Button key="submit" color="blue" onClick={() => openWizard("add")}>
							<Plus size={20} /> Add QR Codes
						</Button>
					</>
				) : null
			}
			dataTrueLength={trueLength}
			cursorColumn="QRCodeID"
			paginationIsLoading={isLoading || skipQuery || props.isLoading}
			pageSize={state.pageSize}
			previousEvent={getValidationQRCodesForOrganization?.previousEvent}
			searchTokens={paginate.searchTokens}
			showSitePicker={false}
			showDateRangePicker={false}
			fetchPaginatedData={fetchPaginatedData}
		/>
	);
}
