import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useHasClientAdmin, usePermissions } from "../../hooks";
import AlertMessage from "../../components/layout/AlertMessage";
import Breadcrumb from "../../components/layout/Breadcrumb";
import ContextMenu from "../../components/menu/ContextMenu";
import ContextMenuLink from "../../components/menu/ContextMenuLink";
import GeofencingContainer from "./GeofencingContainer";
import HoursContainer from "./HoursContainer";
import InstallationContainer from "./InstallationContainer";
import InvoicesContainer from "./InvoicesContainer";
import Loader from "../../components/layout/Loader";
import PageTitle from "../../components/layout/PageTitle";
import PlatesContainer from "./PlatesContainer";
import SiteMap from "./site-map";
import SiteSettingsContainer from "./SiteSettingsContainer";
import SpecialInstructionsContainer from "./SpecialInstructionsContainer";
import { routing } from "../../helpers";
import { useTranslation } from "react-i18next";

export default function SiteContainer(props) {
	const { t: translate } = useTranslation();

	useEffect(() => {
		routing.loadSite({
			availableSites: props.availableSites,
			history: props.history,
			setSite: props.setSite,
			match: props.match,
			selectedOperator: props.selectedOperator,
			selectedOrganization: props.selectedOrganization,
		});
	}, []);

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const hasClientAdmin = useHasClientAdmin(props.selectedOrganization.ClientID);
	const isSuperAdmin = isAdmin && !isCSAdmin;

	if (!props.selectedSite) {
		return <Loader />;
	}

	const siteHasAnpr = props.selectedSite.HasANPR;

	return (
		<div>
			<ContextMenu menuCollapsed={props.menuCollapsed}>
				<ContextMenuLink
					key="settings"
					path={`${props.match.url}/settings`}
					name={translate("ContextMenu.Site.Settings.General")}
					location={props.location}
				/>
				<ContextMenuLink
					key="hours"
					path={`${props.match.url}/hours`}
					name={translate("ContextMenu.Site.Settings.Hours")}
					location={props.location}
				/>
				<ContextMenuLink
					key="special-instructions"
					path={`${props.match.url}/special-instructions`}
					name={translate("ContextMenu.Site.Settings.Instructions")}
					location={props.location}
				/>
				{siteHasAnpr && (
					<ContextMenuLink
						key="plates"
						path={`${props.match.url}/plates`}
						name={translate("ContextMenu.Site.Settings.Plates")}
						location={props.location}
					/>
				)}
				{(isSuperAdmin || hasClientAdmin) && (
					<ContextMenuLink
						key="installation"
						path={`${props.match.url}/installation`}
						name={translate("ContextMenu.Site.Settings.Installation")}
						location={props.location}
					/>
				)}
				<ContextMenuLink
					key="geofencing"
					path={`${props.match.url}/geofencing`}
					name={translate("ContextMenu.Site.Settings.Geofencing")}
					location={props.location}
				/>
				<ContextMenuLink
					key="map"
					path={`${props.match.url}/site-map`}
					name={translate("ContextMenu.Site.Settings.Map")}
					location={props.location}
				/>
				{/* {isAdmin && (
					<ContextMenuLink
						key="invoices"
						path={`${props.match.url}/invoices`}
						name={translate("ContextMenu.Site.Settings.Invoices")}
						location={props.location}
					/>
				)} */}
			</ContextMenu>

			<AlertMessage
				hidden={props.selectedSite.IsOperational}
				text="This site is NOT operational, as it does not have any billing details set."
				backgroundColor="red"
				borderColor="red"
				textColor="white"
			/>

			<AlertMessage
				hidden={!props.selectedSite.IsDeactivated}
				text="This site is NOT visible to customers, as it is deactivated."
				backgroundColor="red"
				borderColor="red"
				textColor="white"
			/>

			<Breadcrumb
				items={[
					{
						text: props.selectedOrganization
							? props.selectedOrganization.Name
							: "",
						onClick: () =>
							props.history.push(
								`/organization/${props.selectedOrganization.OrganizationID}`
							),
					},
					{
						text: props.selectedSite ? props.selectedSite.Name : "",
						onClick: () => props.history.push(props.match.url),
					},
				]}
			/>
			<Switch>
				<Route path={`${props.match.url}/spaces`}>
					<div>
						<PageTitle>Parking Spaces</PageTitle>
						<div {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/settings`}>
					<div>
						<PageTitle>Site Settings</PageTitle>
						<SiteSettingsContainer {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/hours`}>
					<div>
						<PageTitle>Opening Hours</PageTitle>
						<HoursContainer {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/special-instructions`}>
					<div>
						<PageTitle>Special Instructions</PageTitle>
						<SpecialInstructionsContainer {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/plates`}>
					<div>
						<PageTitle>Plates</PageTitle>
						<PlatesContainer {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/installation`}>
					<div>
						<PageTitle>Installation</PageTitle>
						<InstallationContainer {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/geofencing`}>
					<div>
						<PageTitle>Geofencing</PageTitle>
						<GeofencingContainer {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/site-map`}>
					<div>
						<PageTitle>Map</PageTitle>
						<SiteMap {...props} />
					</div>
				</Route>
				<Route path={`${props.match.url}/invoices`}>
					<div>
						<PageTitle>Invoices</PageTitle>
						<InvoicesContainer {...props} />
					</div>
				</Route>

				<Redirect to={`${props.match.url}/settings`} />
			</Switch>
		</div>
	);
}
