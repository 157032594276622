import CreatableSelect from "react-select/creatable";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const overrideStyles = {
	multiValueRemove: (base, state) => {
		return state.data.isFixed ? { ...base, display: "none" } : base;
	},
};

const components = {
	DropdownIndicator: null,
};

const Wrapper = styled.div`
	.react-select__control {
		background-color: ${colours.background};
		border-color: ${colours.borderGrey};
		border-radius: 0;
		cursor: pointer;
		min-height: 40px;
		transition: 0.2s ease;

		&:hover {
			border-color: ${colours.borderGrey};
			opacity: 0.8;
		}

		&.react-select__control--is-focused {
			border-color: ${colours.green};
			box-shadow: none;
		}
	}

	.react-select__indicator-separator {
		background-color: ${colours.background};
	}

	.react-select__dropdown-indicator,
	.react-select__clear-indicator {
		color: ${colours.borderGrey};
		transition: 0.2s ease;

		&:hover {
			color: ${colours.borderGrey};
			opacity: 0.8;
		}
	}

	.react-select__single-value {
		color: ${colours.darkGrey};
		overflow: visible;
	}

	.react-select__multi-value__label {
		color: ${colours.darkGrey};
	}

	.react-select__menu {
		border-radius: 0;
		border: 1px solid ${colours.borderGrey};
		box-shadow: none;
		margin-top: -1px;
	}

	.react-select__menu-list {
		padding: 0;
	}

	.react-select__option {
		color: ${colours.darkGrey};
		cursor: pointer;

		&.react-select__option--is-focused {
			background-color: ${colours.lightGrey};
		}

		&.react-select__option--is-selected {
			background-color: ${colours.green};
			color: ${colours.white};
		}
	}
`;

export default function MultiTextInput(props) {
	return (
		<Wrapper>
			<CreatableSelect
				components={components}
				styles={overrideStyles}
				classNamePrefix="react-select"
				isMulti
				{...props}
			/>
		</Wrapper>
	);
}
