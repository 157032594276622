import React, { useState } from "react";
import SearchBox from "../../components/layout/SearchBox";
import Button from "../../components/layout/Button";
import { useSearchUsers } from "./searchHooks";
import Card from "../../components/layout/Card";
import styled from "styled-components";
import VehiclesTable from "./VehiclesTable";
import { colours } from "../../styles";
import { MoreHorizontal } from "react-feather";
import DropdownMenu from "../../components/layout/DropdownMenu";
import UserDetails from "../../components/details-pages/users/UserDetails";
import PageActions, { LeftActions } from "../../components/layout/PageActions";
import PageTitle from "../../components/layout/PageTitle";

const StyledCard = styled.div`
	background-color: ${colours.offWhite};
	margin: 32px 0;
	padding: 16px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StyledField = styled.div`
	flex: 1;
`;

const StyledH2 = styled.h2`
	margin: 0;
	color: #666;
`;

const StyledH3 = styled.h3`
	margin: 0;
	margin-top: 8px;
	color: #999;
`;

const SearchBoxStyles = {
	maxWidth: "200px",
};

const SearchBoxStylesWide = {
	maxWidth: "300px",
};

export default function UsersTable() {
	const [email, setEmail] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [phone, setPhone] = useState(null);
	const [numberplate, setNumberplate] = useState(null);
	const [searchArgs, setSearchArgs] = useState({});

	const [shouldShowUserDetailsModal, setShouldShowUserDetailsModal] =
		useState(false);
	const [userDetailsUser, setUserDetailsUser] = useState(null);

	const { data: userSearchData } = useSearchUsers(searchArgs);

	const userData = userSearchData?.getUsersBySearch;

	const handleOpenUserDetailsModal = (user) => {
		setUserDetailsUser(user);
		setShouldShowUserDetailsModal(true);
	};

	const closeUserDetailsModal = () => {
		setShouldShowUserDetailsModal(false);
		setUserDetailsUser(null);
	};

	const handleSearchClick = () => {
		setSearchArgs({
			email,
			firstName,
			lastName,
			phone,
			numberplate,
		});
	};

	const handleSearchOnEnter = (e) => {
		if (e.key === "Enter") handleSearchClick();
	};

	async function handleEmailChange(value) {
		setEmail(value);
	}

	async function handleFirstNameChange(value) {
		setFirstName(value);
	}

	async function handleLastNameChange(value) {
		setLastName(value);
	}

	async function handlePhoneChange(value) {
		setPhone(value);
	}

	async function handleNumberplateChange(value) {
		setNumberplate(value);
	}

	if (shouldShowUserDetailsModal && userDetailsUser) {
		return (
			<UserDetails
				close={closeUserDetailsModal}
				user={userDetailsUser}
				global={true}
				clientAdmin={true}
			/>
		);
	}

	return (
		<>
			<PageTitle>Search Users</PageTitle>

			<PageActions>
				<LeftActions style={{ display: "flex", gap: "16px" }}>
					<SearchBox
						style={SearchBoxStylesWide}
						value={email}
						onChange={handleEmailChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"Email"}
					/>
					<SearchBox
						style={SearchBoxStyles}
						value={firstName}
						onChange={handleFirstNameChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"First Name"}
					/>
					<SearchBox
						style={SearchBoxStyles}
						value={lastName}
						onChange={handleLastNameChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"Last Name"}
					/>
					<SearchBox
						style={SearchBoxStyles}
						value={phone}
						onChange={handlePhoneChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"Phone"}
					/>
					<SearchBox
						style={SearchBoxStyles}
						value={numberplate}
						onChange={handleNumberplateChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"Plate"}
					/>
					<Button color="blue" onClick={handleSearchClick}>
						Search
					</Button>
				</LeftActions>
			</PageActions>

			<p style={{ margin: "0 16px" }}>
				*The search function works only for exact matches. Ensure that you enter
				the correct spelling or IDs for the best results.
			</p>

			{userData?.length === 0 && (
				<Card>
					<StyledH2>No users found</StyledH2>
				</Card>
			)}

			{userData?.map((user) => {
				return (
					<Card key={user.UserID}>
						<h1>User</h1>
						<StyledCard>
							<StyledField>
								<StyledH2>Name: </StyledH2>
								<StyledH3>{user.FirstName + " " + user.LastName}</StyledH3>
							</StyledField>
							<StyledField>
								<StyledH2>Email</StyledH2>
								<StyledH3>{user.Email}</StyledH3>
							</StyledField>
							<StyledField>
								<StyledH2>Phone</StyledH2>
								<StyledH3>{user.Phone}</StyledH3>
							</StyledField>
							<StyledField>
								<StyledH2>User Details</StyledH2>
								<DropdownMenu
									triggerContent={<MoreHorizontal size={24} />}
									items={[
										<div
											key="user-details"
											onClick={() => handleOpenUserDetailsModal(user)}
										>
											User Details
										</div>,
									]}
								/>
							</StyledField>
						</StyledCard>
						<h1>Vehicles</h1>
						<VehiclesTable data={user.Numberplates} />
					</Card>
				);
			})}
		</>
	);
}
