import { deleteJSON, getJSON, postForm, postJSON, putJSON } from "../index";
import qs from "qs";

const basePath = "/installation/api";
const kioskPath = "/kiosk/api";
const controlBoxPath = "/control-box/api";

//Lease Park v2 routes
export function createLeasePark(organizationId, leasePark) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-park`,
		leasePark
	);
}

export function getLeaseParksForOrganization(organizationId, options) {
	const params = qs.stringify(options);
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-parks?${params}`
	);
}

export function updateLeasePark(organizationId, leaseParkId, leasePark) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-park/${leaseParkId}`,
		leasePark
	);
}

export function deleteLeasePark(organizationId, leaseParkId) {
	return deleteJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-park/${leaseParkId}`
	);
}

//Lease Rate v2 routes
export function getLeaseRatesForOrganizationId(organizationId, options) {
	const params = qs.stringify(options);
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-rates?${params}`
	);
}
export function createLeaseRate(organizationId, data) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-rate`,
		data
	);
}
export function updateLeaseRate(organizationId, leaseRateId, data) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-rates/${leaseRateId}`,
		data
	);
}
export function deleteLeaseRate(organizationId, leaseRateId) {
	return deleteJSON(
		`${basePath}/v1/admin/organization/${organizationId}/lease-rates/${leaseRateId}`
	);
}

//Fixed Term Rates

export function getFixedTermBookingsByOrganization(organizationId, options) {
	const params = qs.stringify(options);
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/fixed-term-bookings?${params}`
	);
}

export function getFixedTermRatesForOrganizationId(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/fixed-term-rates?${params}`
	);
}
export function createFixedTermRate(organizationId, data) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/fixed-term-rate`,
		data
	);
}
export function updateFixedTermRate(organizationId, fixedTermRateId, data) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/fixed-term-rates/${fixedTermRateId}`,
		data
	);
}
export function deleteFixedTermRate(organizationId, fixedTermRateId) {
	return deleteJSON(
		`${basePath}/v1/admin/organization/${organizationId}/fixed-term-rates/${fixedTermRateId}`
	);
}

export function createOrganizationAccessGroup(organizationId, data) {
	return postJSON(
		`${basePath}/v2/admin/organization/${organizationId}/organization-access-group`,
		data
	);
}

export function deleteOrganizationAccessGroupById(organizationId, groupId) {
	return deleteJSON(
		`${basePath}/v1/admin/organization/${organizationId}/organization-access-group/${groupId}`
	);
}

export function getAdminAuditLog(options) {
	const params = qs.stringify(options);

	return getJSON(`${basePath}/v1/admin/audit-log?${params}`);
}

export function getAuditLogByOrganizationId(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/audit-log?${params}`
	);
}

export function getSitesForOrganization(organizationId) {
	return getJSON(`${basePath}/v1/admin/organizations/${organizationId}/sites`);
}

export function getOpeningsReport(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/node-interactions/report?${params}`
	);
}

export function getOrganizationAccessGroups(organizationId) {
	return getJSON(
		`${basePath}/v2/admin/organization/${organizationId}/organization-access-groups`
	);
}

export function getOrganizationAccessGroupsWithSites(organizationId) {
	return getJSON(
		`${basePath}/v2/admin/organization/${organizationId}/organization-access-groups?include-sites=true`
	);
}

export function getSite(siteId) {
	return getJSON(`${basePath}/site/${siteId}`);
}

export function deleteSiteById(siteId) {
	return deleteJSON(`${basePath}/v1/admin/sites/${siteId}`);
}

export function deleteOrganizationById(organizationId) {
	return deleteJSON(`${basePath}/v1/admin/organization/${organizationId}`);
}

export function getSensitiveSiteData(siteId) {
	return getJSON(`${basePath}/v1/admin/site/${siteId}/sensitive-data`);
}

export function getSiteAccessGroups(organizationId, siteId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/site/${siteId}/site-organization-access-groups`
	);
}

export function getSiteOperationalStatus(siteId) {
	return getJSON(`${basePath}/admin/sites/${siteId}/operational-status`);
}

export function getAccessGroupsAssignedToSite(organizationId, siteId) {
	if (siteId) {
		return getJSON(
			`${basePath}/v2/admin/organization/${organizationId}/organization-access-groups?assigned-to-site-id=${siteId}`
		);
	} else {
		return Promise.resolve([]);
	}
}

export function setOrganizationAccessGroupsForSite(
	organizationId,
	siteId,
	organizationAccessGroupIds
) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/site/${siteId}/site-organization-access-groups/set`,
		{ organizationAccessGroupIds: organizationAccessGroupIds }
	);
}

export function getUserLeaseReport(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v2/admin/organization/${organizationId}/user-leases/report?${params}`
	);
}

export function getValidationBeaconsByOrganizationId(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/admin/organization/${organizationId}/validation-beacons?${params}`
	);
}

export function importUsersForOrganization(organizationId, data) {
	return postForm(
		`${basePath}/v1/admin/organization/${organizationId}/user-organization-access-groups/import`,
		data
	);
}

export function setUserAndOrganizationAccessGroups(organizationId, data) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-organization-access-groups/set`,
		data
	);
}

export function updateOrganizationAccessGroup(organizationId, groupId, data) {
	return putJSON(
		`${basePath}/v2/admin/organization/${organizationId}/organization-access-group/${groupId}`,
		data
	);
}

export function updateSite(siteId, data) {
	return putJSON(`${basePath}/admin/site/${siteId}`, data);
}

export function openGate(siteId, nodeId) {
	return postJSON(`${basePath}/admin/site/${siteId}/node/${nodeId}/opengate`, {
		adminPortal: true,
	});
}

export function getSiteOpeningHours(siteId) {
	return getJSON(`${basePath}/admin/sites/${siteId}/hours`);
}

export function updateSiteOpeningHours(siteId, data) {
	return putJSON(`${basePath}/v2/admin/sites/${siteId}/hours/`, data);
}

export function getChildrenSites(siteId) {
	return getJSON(`${basePath}/v0/admin/sites/child/` + siteId);
}

// Special Instructions
export function getSpecialInstructions(siteId) {
	return getJSON(`${basePath}/admin/site/${siteId}/special-instructions`);
}

export function updateInstructionOrder(siteId, data) {
	return putJSON(
		`${basePath}/admin/site/${siteId}/update-instruction-order`,
		data
	);
}

export function deleteSpecialInstruction(siteId, specialInstructionId) {
	return deleteJSON(
		`${basePath}/admin/site/${siteId}/special-instructions/${specialInstructionId}`
	);
}

export function createSpecialInstruction(siteId, specialInstruction) {
	return postJSON(
		`${basePath}/admin/site/${siteId}/special-instructions`,
		specialInstruction
	);
}

export function updateSpecialInstruction(
	siteId,
	specialInstructionId,
	specialInstruction
) {
	return putJSON(
		`${basePath}/admin/site/${siteId}/special-instructions/${specialInstructionId}`,
		specialInstruction
	);
}

// Geofencing
export function getZonesBySite(siteId) {
	return getJSON(`${basePath}/admin/site/${siteId}/zones`);
}

export function deleteZone(siteId, zoneId) {
	return deleteJSON(`${basePath}/admin/site/${siteId}/zone/${zoneId}`);
}

export function createZone(siteId, data) {
	return postJSON(`${basePath}/admin/site/${siteId}/zone`, data);
}

export function updateZone(siteId, zoneId, data) {
	return putJSON(`${basePath}/admin/site/${siteId}/zone/${zoneId}`, data);
}

// Validation Beacons
export function createValidationBeacon(organizationId, data) {
	return postJSON(
		`${basePath}/admin/organization/${organizationId}/validation-beacon`,
		data
	);
}

export function updateValidationBeacon(organizationId, beaconId, data) {
	return putJSON(
		`${basePath}/admin/organization/${organizationId}/validation-beacon/${beaconId}`,
		data
	);
}

export function deleteValidationBeaconById(organizationId, beaconId) {
	return deleteJSON(
		`${basePath}/admin/organization/${organizationId}/validation-beacon/${beaconId}`
	);
}

export function checkValidationBeaconId(organizationId, beaconId) {
	return getJSON(
		`${basePath}/admin/organization/${organizationId}/check-validation-beacon-id?beaconId=${beaconId}`
	);
}

// Promotions
export function checkPromotionCode(organizationId, promotionCode) {
	return getJSON(
		`${basePath}/admin/organization/${organizationId}/check-promotion-code?promotionCode=${promotionCode}`
	);
}

export function createPromotion(organizationId, data) {
	return postJSON(
		`${basePath}/admin/organization/${organizationId}/promotion`,
		data
	);
}

export function updatePromotion(organizationId, promotionId, data) {
	return putJSON(
		`${basePath}/admin/organization/${organizationId}/promotion/${promotionId}`,
		data
	);
}

export function deletePromotion(organizationId, data) {
	return putJSON(
		`${basePath}/admin/organization/${organizationId}/promotion/delete`,
		data
	);
}

export function togglePromotion(organizationId, promotionId, data) {
	return putJSON(
		`${basePath}/admin/organization/${organizationId}/promotion/${promotionId}/toggle`,
		data
	);
}

export function importPromotionsForOrganization(organizationId, data) {
	return postForm(
		`${basePath}/admin/organization/${organizationId}/promotion/import`,
		data
	);
}

// Site Hardware
export function getNodesForSite(siteId) {
	return getJSON(`${basePath}/admin/site/${siteId}/nodes`);
}

export function getNodesLiteForSite(siteId) {
	return getJSON(`${basePath}/v0/admin/nodes/lite/${siteId}`);
}

export function getNodesImportCSVSchema() {
	return getJSON(`${basePath}/v0/admin/nodes/schema`);
}

export function importNodesCSV(file) {
	let data = new FormData();
	data.append("file", file);

	return postJSON(`${basePath}/v0/admin/nodes/import`, data, true);
}

export function importCamerasCSV(file) {
	let data = new FormData();
	data.append("file", file);

	return postJSON(`${basePath}/admin/cameras/import`, data, true);
}

export function getBeaconsForSite(siteId) {
	return getJSON(`${basePath}/admin/site/${siteId}/beacons`);
}

export function getNodeBeaconsForSite(siteId) {
	return getJSON(`${basePath}/admin/site/${siteId}/node-beacons`);
}

export function createNodeBeaconForSite(siteId, data) {
	return postJSON(`${basePath}/admin/site/${siteId}/node-beacon`, data);
}

export function deleteNodeBeaconForSite(siteId, nodeBeaconId) {
	return deleteJSON(
		`${basePath}/admin/site/${siteId}/node-beacon/${nodeBeaconId}`
	);
}

export function getNodeGroupsForSite(siteId) {
	return getJSON(`${basePath}/admin/site/${siteId}/node-groups`);
}

export function createGroup(siteId, group) {
	return postJSON(`${basePath}/admin/site/${siteId}/group`, group);
}

export function updateNodeGroup(siteId, nodeGroupId, data) {
	return putJSON(
		`${basePath}/admin/site/${siteId}/node-group/${nodeGroupId}`,
		data
	);
}

export function deleteNodeGroup(siteId, nodeGroupId) {
	return deleteJSON(
		`${basePath}/admin/site/${siteId}/node-group/${nodeGroupId}`
	);
}

export function updateNodeGroupMappings(siteId, nodeGroupId, data) {
	return putJSON(
		`${basePath}/admin/site/${siteId}/node-group-mappings/${
			nodeGroupId !== 0 ? nodeGroupId : "default"
		}`,
		data
	);
}

export function triggerNode(siteId, nodeId) {
	return postJSON(`${basePath}/admin/site/${siteId}/node/${nodeId}/trigger`, {
		adminPortal: true,
	});
}

export function closeGate(siteId, nodeId) {
	return postJSON(`${basePath}/admin/site/${siteId}/node/${nodeId}/closegate`);
}

export function toggleStateNodeSite(nodeSiteId, data) {
	return postJSON(`${basePath}/v0/admin/node-site/${nodeSiteId}/toggle`, data);
}

export function deleteNodeSite(nodeSiteId) {
	return deleteJSON(`${basePath}/v0/admin/node-site/${nodeSiteId}`);
}

export function updateNodeSite(nodeSiteId, data) {
	return putJSON(`${basePath}/v0/admin/node-site/${nodeSiteId}`, data);
}

export function createNodeSite(siteId, data) {
	return postJSON(`${basePath}/admin/site/${siteId}/node`, data);
}

export function clientSourcedNoteInteractionResult(
	encryptedCommand,
	outcomeType
) {
	return postJSON(
		`${basePath}/v1/node-interaction/client-sourced-node-interaction-result`,
		{
			openGateCommand: { encryptedCommand: encryptedCommand },
			outcomeType: outcomeType,
		}
	);
}

export function addBeaconForSite(siteId, data) {
	return postJSON(`${basePath}/admin/site/${siteId}/beacon`, data);
}

export function deleteBeaconForSite(siteId, beaconSiteId) {
	return deleteJSON(`${basePath}/admin/site/${siteId}/beacon/${beaconSiteId}`);
}

export function createOrganization(data) {
	return postJSON(`${basePath}/v1/admin/organization`, data);
}

export function createSite(data) {
	return postJSON(`${basePath}/admin/site`, data);
}

export function getBeaconDataByUniqueId(siteId, uniqueId) {
	return getJSON(
		`${basePath}/admin/site/${siteId}/beacon/get-beacon-data?uniqueId=${uniqueId}`
	);
}

export function validateBeaconId(uniqueId) {
	return getJSON(`${basePath}/admin/validate-beacon-id?beaconId=${uniqueId}`);
}

// Rates
export function getCurrentRatesBySiteId(siteId) {
	if (siteId) {
		return getJSON(`${basePath}/admin/site/${siteId}/rates/current`);
	} else {
		return Promise.resolve([]);
	}
}

export function getCurrentRatesByOrganizationId(organizationId) {
	return getJSON(
		`${basePath}/admin/organization/${organizationId}/rates/current`
	);
}

export function createRate(siteId, rate) {
	return postJSON(`${basePath}/admin/site/${siteId}/rate`, rate);
}

export function updateRate(siteId, rateId, rate) {
	return putJSON(`${basePath}/admin/site/${siteId}/rate/${rateId}`, rate);
}

export function retireRate(siteId, rateId) {
	return deleteJSON(`${basePath}/admin/site/${siteId}/rate/${rateId}/retire`);
}

export function getValidationBeaconsBySiteId(siteId) {
	if (siteId) {
		return getJSON(`${basePath}/admin/site/${siteId}/validation-beacons`);
	} else {
		return Promise.resolve([]);
	}
}

export function getBeaconSitesByBeaconId(beaconId) {
	return getJSON(`${basePath}/admin/beacon/${beaconId}/beacon-sites`);
}

export function createBeacon(data) {
	return postJSON(`${basePath}/admin/beacon`, data);
}

export function testRate(
	organizationId,
	siteId,
	entryTime,
	exitTime,
	groups = [],
	beacons = [],
	promotion,
	qrCode,
	co2Emissions
) {
	let query = `entry-date-time=${entryTime}&exit-date-time=${exitTime}`;

	if (groups.length) {
		query = `${query}&${groups
			.map((id) => `organization-access-group-id=${id}`)
			.join("&")}`;
	} else {
		query = `${query}&organization-access-group-id=null`;
	}

	if (beacons.length) {
		query = `${query}&${beacons
			.map((id) => `validation-beacons=${id}`)
			.join("&")}`;
	} else {
		query = `${query}&validation-beacons=null`;
	}

	if (co2Emissions) {
		query = `${query}&co2-emissions=${co2Emissions}`;
	} else {
		query = `${query}&co2-emissions=201`;
	}

	if (qrCode) {
		query = `${query}&qr-code=${qrCode}`;
	} else {
		query = `${query}&qr-code=null`;
	}

	if (promotion) {
		query = `${query}&promotion-code=${promotion}`;
	} else {
		query = `${query}&promotion-code=null`;
	}

	if (siteId) {
		return getJSON(
			`${basePath}/v1/admin/organization/${organizationId}/site/${siteId}/rates/test?${query}`
		);
	} else {
		return Promise.resolve([]);
	}
}

export function getRateConflict(siteId, data) {
	return postJSON(`${basePath}/admin/site/${siteId}/rate/conflict`, data);
}

export function getAllKiosks() {
	return getJSON(`${kioskPath}/v1/admin/kiosks`);
}

export function importKiosksCsv(file) {
	let data = new FormData();
	data.append("file", file);
	return postJSON(`${kioskPath}/v1/admin/kiosks/import`, data, true);
}

export function getAllControlBoxes() {
	return getJSON(`${controlBoxPath}/v1/admin/control-boxes`);
}

export function importControlBoxesCsv(file) {
	let data = new FormData();
	data.append("file", file);
	return postJSON(
		`${controlBoxPath}/v1/admin/control-boxes/import`,
		data,
		true
	);
}
