import React, { useEffect, useState } from "react";
import * as steps from "./steps";
import Alert from "react-s-alert";
import WizardLayout from "../WizardLayout";
import { useTranslation } from "react-i18next";
import {
	useUpsertOrganizationAccessGroup,
	useDeleteOrganizationAccessGroup,
	useGetEventSeriesByGroupId,
	useGetGroupById,
} from "./data";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import {
	getHoursData,
	handleApiError,
	getTitleByMode,
	uniqueSites,
} from "./GroupWizard.helpers";
import { getGroupValues } from "./GroupWizard.validations";
import { usePermissions } from "../../../hooks";
import { chain, map } from "lodash";

/**
 * Groups wizard component.
 *
 * @param {object} props
 * @param {object} props.group
 */
export default function GroupsWizard(props) {
	const upsertOrganizationAccessGroup = useUpsertOrganizationAccessGroup();
	const deleteOrganizationAccessGroup = useDeleteOrganizationAccessGroup();
	const canManageEvents = usePermissions(null, "ManageEvents", true);

	const { t: translate } = useTranslation();
	const [siteSpaces, setSiteSpaces] = useState([{}]);
	const [eventSeries, setEventSeries] = useState();
	const [isLoadingSitesAndSpaces, setIsLoadingSitesAndSpaces] = useState(
		props.mode === "update"
	);
	const [isLoadingEventSeries, setIsLoadingEventSeries] = useState(true);

	const { sites, isLoading: isGroupsLoading } = useGetGroupById(
		props.group?.OrganizationAccessGroupID
	);

	const {
		eventSeries: eventSeriesRawFromAPI,
		isLoading: isGettingEventSeriesFromAPI,
	} = useGetEventSeriesByGroupId(props.group?.OrganizationAccessGroupID);

	useEffect(() => {
		if (props.mode !== "update" || isGroupsLoading || !sites) {
			return;
		}

		const uniqueSiteList = uniqueSites(sites);

		// Return early if no sites
		if (uniqueSiteList?.length === 0) {
			setSiteSpaces([{}]);
			setIsLoadingSitesAndSpaces(false);
			return;
		}

		const newSiteSpaces = uniqueSiteList.flatMap(
			({ SiteID, Name, LeaseParks }) => {
				if (LeaseParks && LeaseParks.length > 0) {
					return LeaseParks.map(({ LeaseParkID, Name: leaseParkName }) => ({
						selectedSite: {
							value: SiteID,
							label: Name,
						},
						selectedSpace: {
							value: LeaseParkID,
							label: leaseParkName,
						},
					}));
				} else {
					return {
						selectedSite: {
							value: SiteID,
							label: Name,
						},
						selectedSpace: {
							value: null,
							label: null,
						},
					};
				}
			}
		);

		setSiteSpaces(newSiteSpaces);
		setIsLoadingSitesAndSpaces(false);
	}, [isGroupsLoading, sites]);

	useEffect(() => {
		if (props.mode === "update" && props.group) {
			if (!isGettingEventSeriesFromAPI && eventSeriesRawFromAPI) {
				setEventSeries(
					eventSeriesRawFromAPI.map((series) => ({
						value: series.EventSeriesID,
						label: series.Name,
					}))
				);
				setIsLoadingEventSeries(false);
			}
		} else {
			setIsLoadingEventSeries(false);
		}
	}, [isGettingEventSeriesFromAPI, eventSeriesRawFromAPI]);

	let _steps = [];

	if (props.mode === "add") {
		_steps = [steps.editStep];
	} else if (props.mode === "update") {
		_steps = [steps.editStep];
	} else if (props.mode === "remove") {
		_steps = [steps.removeStep];
	}

	if (
		isGroupsLoading ||
		isLoadingSitesAndSpaces ||
		isLoadingEventSeries ||
		isGettingEventSeriesFromAPI
	) {
		return <LoadingPlaceholder />;
	}

	const handleSubmit = async (values) => {
		if (props.mode === "remove") {
			try {
				await deleteOrganizationAccessGroup({
					variables: {
						organizationAccessGroupId: props.group.OrganizationAccessGroupID,
					},
				});
				Alert.success("Access group removed");
				props.close(true);
			} catch (error) {
				switch (error.errors[0].code) {
					case "GroupHasAssociatedRate":
						Alert.error("Cannot remove group, group has associated rate.");
						break;
					default:
						Alert.error(translate("Errors.Generic"));
						break;
				}
			}
		}

		if (values.mode === "add" || values.mode === "update") {
			const groupDataDTO = {
				OrganizationAccessGroupID: values.group?.OrganizationAccessGroupID,
				OrganizationID: props.selectedOrganization.OrganizationID,
				Name: values.name,
				ForceSessionlessAccessOnSites: values.sessionlessAccess,
				SpaceAccountID: values.account?.value ?? null,
				HasANPRAccess: values.hasANPRAccess,
				WaiveConvenienceFee: values.waiveConvenienceFee, // Convert 1/0 to true/false
				IsEnforcementExempt: values.isEnforcementExempt,
				EventGroup: values.isEvent,
				AllowWhitelistedPlates: values.allowWhitelistedPlates,
				AllowMultiEntry: values.allowMultiAccess, //disabled right now
				...getHoursData(values),
			};

			const mappingDataDTO = {
				leaseParkIds: values.siteSpaces
					.filter((s) => s.selectedSpace)
					.map((s) => s.selectedSpace.value),

				leaseRateIds: chain(values.leaseRates)
					.filter((item) => item.value >= 0) // Filter out the placeholder which has an ID of -1
					.map((item) => item.value)
					.value(),

				siteIds: values.siteSpaces.map((s) => s.selectedSite.value),
				locationBasedNodeIds: map(values.locationBasedNodes, "value"),
				restrictedAccessNodeIds: map(values.restrictedAccessNodes, "value"),
			};

			try {
				await upsertOrganizationAccessGroup({
					variables: { groupData: groupDataDTO, mappingData: mappingDataDTO },
				});

				Alert.success(
					translate(
						values.mode === "add"
							? "Access group added"
							: "Access group updated"
					)
				);
				props.close(true);
			} catch (error) {
				handleApiError(error, translate);
			}
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title={(values) => getTitleByMode(values.mode)}
			values={getGroupValues(props, siteSpaces, eventSeries, canManageEvents)}
			onSubmit={handleSubmit}
			steps={_steps}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
