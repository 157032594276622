import { MoreHorizontal, Plus } from "react-feather";
import React, { useContext, useState } from "react";
import {
	useQueryData,
	useSearchFilter,
	useHasPermissionsOnEverySite,
	useCanManageValidations,
} from "../../../hooks";
import { AppContext } from "../../../context/app-context";
import Button from "../../layout/Button";
import DropdownMenu from "../../layout/DropdownMenu";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import ReportWrapper from "../ReportWrapper";
import ValidationWizard from "../../wizards/validation-wizard";
import gql from "graphql-tag";
import {
	VALIDATION_TYPE_OPTIONS,
	VALIDATION_WIZARD_TYPE,
} from "../../../helpers/constants";
import { formatDiscountTypeDisplay } from "../../../helpers/format";

const defaultSorting = [
	{
		id: "Location",
		desc: false,
	},
];

export default function ValidationBeacons(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			"start-date-time": null,
			"end-date-time": null,
		},
		search: "",
		beacon: "",
		wizardOpen: false,
	});

	const {
		state: { availableSites },
	} = useContext(AppContext);

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const {
		data: { getOrganizationAccessGroups: organizationAccessGroups },
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getOrganizationAccessGroups(organizationId: $organizationId) {
					OrganizationAccessGroupID
					OrganizationID
					Name
				}
			}
		`,
		{
			organizationId,
		}
	);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = (mode, beacon) => {
		setState((_state) => ({
			..._state,
			beacon,
			wizardOpen: true,
			wizardMode: mode,
		}));
	};

	const canEditSettings = useCanManageValidations();

	//includes admin, client admin and site admin user permissions
	const hasEditSettingsOnEverySite = useHasPermissionsOnEverySite(
		"EditSettings",
		availableSites
	);
	const hasValidationPermissionOnEverySite = useHasPermissionsOnEverySite(
		"ManageValidations",
		availableSites
	);
	const hasPermission =
		hasEditSettingsOnEverySite || hasValidationPermissionOnEverySite;

	const columns = [
		{
			id: "Location",
			Header: "Location",
			accessor: (d) => d.Location || "",
			highlightCell: (cellProps) => cellProps.original.Location || "",
		},
		{
			id: "BeaconID",
			Header: "Beacon ID",
			accessor: (d) => d.UniqueID,
			highlightCell: (cellProps) => cellProps.original.UniqueID,
		},
		{
			id: "SelfValidation",
			Header: "Self Validation",
			accessor: (d) => (d.IsSelfValidation ? "Yes" : "No"),
		},
		{
			id: "Type",
			Header: "Type",
			accessor: (d) => d.ValidationType,
			highlightCell: (cellProps) =>
				formatDiscountTypeDisplay(cellProps.original.ValidationType),
		},
		{
			id: "Rates",
			Header: "Rates",
			accessor: (d) => d.rate,
			highlightCell: (cellProps) => cellProps.original.rate,
		},
		{
			id: "Sites",
			Header: "Sites",
			accessor: (d) => d.siteName,
			highlightCell: (cellProps) => cellProps.original.siteName,
		},
	];

	const canManageValidationBeacons = () => [
		...columns,
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (row) =>
				hasPermission ? (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="update"
								onClick={() => openWizard("update", row.original)}
							>
								Edit
							</div>,
							<div
								key="delete"
								onClick={() => openWizard("delete", row.original)}
							>
								Delete
							</div>,
						]}
					/>
				) : null,
			resizable: false,
			width: 50,
		},
	];

	const filteredData = useSearchFilter(props.beacons || [], state.search, [
		"siteName",
		"rate",
		"Location",
		"UniqueID",
		"ValidationType",
	]);

	if (props.isLoading) {
		return <LoadingPlaceholder />;
	}

	const rates = props.beacons.flatMap((beacon) => beacon.Rates);

	if (state.wizardOpen) {
		return (
			<ValidationWizard
				type={VALIDATION_WIZARD_TYPE.BEACON}
				mode={state.wizardMode}
				organizationId={organizationId}
				validationTypeOptions={VALIDATION_TYPE_OPTIONS}
				rateData={rates}
				availableSites={availableSites
					.filter((site) => site.HasValidation)
					.map((site) => ({
						id: site.SiteID,
						name: site.Name,
					}))}
				close={() => {
					setState((_state) => ({
						..._state,
						reloadDataToggle: !state.reloadDataToggle,
						wizardOpen: false,
					}));
					props.refetch();
				}}
				beacon={state.beacon}
				organizationAccessGroups={organizationAccessGroups}
			/>
		);
	}

	return (
		<ReportWrapper
			{...props}
			title="Validation Beacons"
			data={filteredData}
			columns={
				canEditSettings ? canManageValidationBeacons({ openWizard }) : columns
			}
			defaultSorted={defaultSorting}
			updateOptions={updateOptions}
			loading={props.isLoading}
			showDateRangePicker={false}
			showSitePicker={false}
			rightActions={
				hasPermission ? (
					<Button key="submit" color="blue" onClick={() => openWizard("add")}>
						<Plus size={20} /> Add Validation Beacon
					</Button>
				) : null
			}
		/>
	);
}
