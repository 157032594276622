import React, { createContext, useState } from "react";
import { find, sortBy } from "lodash";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getContextHeaders } from "../helpers/headers";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";

const AppContext = createContext({});

function AppContextProvider(props) {
	let [state, setState] = useState({
		selectedOperator: null,
		selectedOrganization: null,
		selectedSite: null,
		reportDateRange: null,
		reportSiteFilter: null,
	});

	const history = useHistory();

	const [
		getOperators,
		{
			data: operatorData,
			loading: operatorsLoading,
			refetch: refetchOperators,
		},
	] = useLazyQuery(
		gql`
			query {
				getOperators {
					OperatorID
					ClientID
					Name
				}
			}
		`,
		{
			notifyOnNetworkStatusChange: true,
			onCompleted: () => {
				if (!operatorData.getOperators.length) {
					history.push("/no-access");
				}

				if (state.selectedOperator) {
					setOperator(
						find(
							operatorData.getOperators,
							(o) => o.OperatorID === state.selectedOperator.OperatorID
						)
					);
				}
			},
			onError: ({ networkError }) => {
				if (networkError) {
					history.push("/error");
					return;
				}
				history.push("/no-access");
			},
		}
	);

	const [
		// eslint-disable-next-line
		getOrganizations,
		{
			data: organizationData,
			loading: organizationsLoading,
			refetch: refetchOrganizations,
		},
	] = useLazyQuery(
		gql`
			query {
				getAllAvailableOrganizations {
					OrganizationID
					OperatorID
					Name
					AccountID
					UserID
					Address
					BillingAddress
					ConsolidateInvoices
					InvoicingEmail
					IsUnmanaged
					ClientID
					DefaultCurrency
					DefaultTimezone
					DefaultTaxRates {
						Name
						Percentage
					}
					CompanyID
					HasEnforcement
					HasOccupancy
					HasEventManagement
					HasGateAccessQRCodes
				}
			}
		`,
		{
			notifyOnNetworkStatusChange: true,
			context: {
				headers: getContextHeaders(state),
			},
			onCompleted: () => {
				if (state.selectedOrganization) {
					setOrganization(
						find(
							organizationData.getAllAvailableOrganizations,
							(o) =>
								o.OrganizationID === state.selectedOrganization.OrganizationID
						)
					);
				}
			},
		}
	);

	const {
		data: sitesData,
		loading: sitesLoading,
		refetch: refetchSites,
	} = useQuery(
		gql`
			query ($organizationId: Int!, $showDeactivated: Boolean) {
				getAvailableSitesForOrganization(
					organizationId: $organizationId
					showDeactivated: $showDeactivated
				) {
					SiteID
					OrganizationID
					Name
					Address
					MaximumCapacity
					Timezone
					SiteType
					HasPublicAccess
					Currency
					HasSessionlessAccess
					GracePeriodSeconds
					IsOperational
					HasCasualParking
					HasLeaseParking
					HasANPR
					Country
					FeeCapType
					FeeCapAmount
					FeeCapRateIDs
					HasValidation
					InstallationID
					IsDeactivated
					SecureParkingExternalID
					Configuration {
						MaxSessions
						MaxSessionDuration
					}
				}
			}
		`,
		{
			variables: {
				organizationId: state.selectedOrganization
					? state.selectedOrganization.OrganizationID
					: null,
				showDeactivated: true,
			},
			context: {
				headers: getContextHeaders(state),
			},
			skip: !state.selectedOrganization,
			notifyOnNetworkStatusChange: true,
			onCompleted: () => {
				if (state.selectedSite) {
					setSite(
						find(
							sitesData.getAvailableSitesForOrganization,
							(s) => s.SiteID === state.selectedSite.SiteID
						)
					);
				}
			},
		}
	);

	const [getUser, { data: userData, loading: userLoading }] = useLazyQuery(
		gql`
			query {
				getCurrentUser {
					UserID
					Email
					FirstName
					LastName
					Phone
					CountryCode
					LastUsedAppVersion
					IsAdmin
					IsCSAdmin
					AdminDashboardEnabled
					FeaturesPreview
					DebugEnabled
					ClientAdmin {
						ClientID
						Name
					}
					SpaceAccountOwners {
						SpaceAccountID
						Name
						OrganizationID
					}
				}
				getPermissionsForCurrentUser {
					UserID
					OrganizationID
					SiteID
					RoleID
					RoleName
					PermissionName
				}
			}
		`
	);

	async function getInitialContext() {
		getOperators();
		getUser();
	}

	async function getAvailableOperators() {
		refetchOperators();
	}

	async function getAvailableOrganizations() {
		refetchOrganizations();
	}

	async function getAvailableSites() {
		refetchSites();
	}

	async function redirectToDefaultPage(
		operatorId,
		organizationId,
		isValidationAccount
	) {
		const home = `/operator/${operatorId}/organization/${organizationId}`;
		const orgPermissions = permissions
			.filter((p) => p.OrganizationID === organizationId)
			.map((p) => p.PermissionName);

		if (isValidationAccount && orgPermissions.includes("ViewAccount")) {
			history.push(`${home}/validation/history`);
		} else if (
			!isValidationAccount &&
			orgPermissions.includes("ViewOrganization")
		) {
			history.push(`${home}/dashboard`);
		} else {
			history.push("/no-access");
		}
	}

	async function setOperator(operator) {
		setState((_state) => ({
			..._state,
			selectedOperator: operator || null,
			selectedOrganization: null,
			selectedSite: null,
			reportSiteFilter: null,
		}));
	}

	async function setOrganization(organization) {
		setState((_state) => ({
			..._state,
			selectedOrganization: organization || null,
			selectedSite: null,
			reportSiteFilter: null,
		}));
	}

	async function setSite(site) {
		setState((_state) => ({
			..._state,
			selectedSite: site || null,
		}));
	}

	function setReportDateRange(dateRange) {
		setState((_state) => ({
			..._state,
			reportDateRange: dateRange,
		}));
	}

	function setReportSiteFilter(siteFilter) {
		setState((_state) => ({
			..._state,
			reportSiteFilter: siteFilter,
		}));
	}

	function spaceAccountOwnerOnly() {
		if (
			currentUser &&
			currentUser.SpaceAccountOwners &&
			currentUser.SpaceAccountOwners.length > 0
		) {
			if (currentUser.IsAdmin || currentUser.IsCSAdmin) return false;

			if (
				permissions &&
				permissions.filter((e) =>
					state.selectedOrganization
						? e.OrganizationID === state.selectedOrganization.OrganizationID
						: false
				).length === 0
			) {
				return true;
			}
		}

		return false;
	}

	const availableOperators = operatorData
		? sortBy(operatorData.getOperators, (o) => o.OperatorID)
		: null;

	const availableOrganizations = organizationData
		? sortBy(organizationData.getAllAvailableOrganizations, (o) =>
				o.Name.toLowerCase()
		  )
		: null;

	const availableSites = sitesData
		? sortBy(sitesData.getAvailableSitesForOrganization, (s) =>
				s.Name.toLowerCase()
		  )
		: [];

	const currentUser = userData ? userData.getCurrentUser : {};
	const permissions = userData ? userData.getPermissionsForCurrentUser : [];

	return (
		<AppContext.Provider
			value={{
				state: {
					...state,
					availableOperators: !operatorsLoading ? availableOperators : null,
					availableOrganizations: !organizationsLoading
						? availableOrganizations
						: null,
					availableSites: !sitesLoading ? availableSites : [],
					currentUser: !userLoading ? currentUser : {},
					permissions: !userLoading ? permissions : [],
					contextLoading:
						operatorsLoading ||
						organizationsLoading ||
						sitesLoading ||
						userLoading,
					spaceAccountOwnerOnly: spaceAccountOwnerOnly(),
				},
				dispatch: {
					getAvailableOperators,
					getAvailableOrganizations,
					getAvailableSites,
					getInitialContext,
					redirectToDefaultPage,
					setOperator,
					setOrganization,
					setReportDateRange,
					setReportSiteFilter,
					setSite,
				},
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
}

export { AppContext, AppContextProvider };
