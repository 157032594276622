import {
	initialGroup,
	restoreAccount,
	restoreLeaseRates,
	restoreLocationBasedNodes,
	restoreRestrictedAccessNodes,
	restoreSites,
} from "./GroupWizard.helpers";
import * as Yup from "yup";

export const getGroupValues = (
	props,
	siteSpaces,
	eventSeries,
	canManageEvents
) => [
	{ name: "mode", value: props.mode, validator: Yup.string() },
	{
		name: "name",
		value: props.group && props.group.Name ? props.group.Name : "",
		validator: Yup.string()
			.max(100, "The group name should be no more than 100 characters long.")
			.required("Please supply a name for this group."),
	},
	{
		name: "group",
		value: props.group ? props.group : initialGroup,
		validator: Yup.object(),
	},
	{
		name: "hasANPRAccess",
		value: Boolean(props?.group?.HasANPRAccess),
		validator: Yup.boolean(),
	},
	{
		name: "waiveConvenienceFee",
		value: Boolean(props?.group?.WaiveConvenienceFee),
		validator: Yup.boolean(),
	},
	{
		name: "sites",
		value: props.group ? restoreSites(props.group) : null,
	},
	{
		name: "account",
		value: restoreAccount(props.group, props.spaceAccounts, props),
		validator: Yup.object().nullable(),
	},
	{
		name: "leaseRates",
		value: props.group ? restoreLeaseRates(props.group) : null,
		validator: Yup.object().nullable(),
	},
	{
		name: "locationBasedNodes",
		value: props.group ? restoreLocationBasedNodes(props.group) : null,
		validator: Yup.object().nullable(),
	},
	{
		name: "restrictedAccessNodes",
		value: props.group ? restoreRestrictedAccessNodes(props.group) : null,
		validator: Yup.object().nullable(),
	},
	{
		name: "sessionlessAccess",
		value: Boolean(props?.group?.ForceSessionlessAccessOnSites),
		validator: Yup.boolean(),
	},
	{
		name: "overridesOpeningHours",
		value:
			props.group && props.group.OverridesOpeningHours
				? props.group.OverridesOpeningHours
				: false,
		validator: Yup.boolean(),
	},
	{
		name: "isEnforcementExempt",
		value: props.group ? Boolean(props.group.IsEnforcementExempt) : false,
		validator: Yup.boolean(),
	},
	{
		name: "siteSpaces",
		value: siteSpaces,
		validator:
			props.mode === "remove"
				? undefined
				: Yup.array()
						.of(
							Yup.object().shape({
								selectedSite: Yup.object()
									.nullable()
									.required("Please select a site."),
								selectedSpace: Yup.object()
									.nullable()
									.when("$isEvent", {
										is: true,
										then: Yup.object()
											.nullable()
											.required("Please select a space."),
										otherwise: Yup.object().nullable(),
									}),
							})
						)
						.min(1, "Please select at least one site and space for this group.")
						.required("Please select a site and space for this group."),
	},
	{
		name: "isEvent",
		value: props.group ? Boolean(props.group.EventGroup) : false,
	},
	{
		name: "allowWhitelistedPlates",
		value: props.group ? Boolean(props.group.AllowWhitelistedPlates) : false,
		validator: Yup.boolean(),
	},
	{
		name: "allowMultiAccess",
		value: props.group ? Boolean(props.group.AllowMultiEntry) : false,
		validator: Yup.boolean(),
	},
	{
		name: "hasEventManagement",
		value: props.selectedOrganization.HasEventManagement && canManageEvents,
		validator: Yup.boolean(),
	},
	{
		name: "hasGetAccessQRCodes",
		value: props.selectedOrganization.HasGetAccessQRCodes,
		validator: Yup.boolean(),
	},
];
