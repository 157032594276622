import { every, isArray, isObject } from "lodash";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../context/app-context";
import { getContextHeaders } from "../helpers/headers";
import { userHasPermission } from "../helpers/permissions";

/**
 * Checks a user's permissions against a provided set of required permissions.
 *
 * Note that every permission provided must return true for all arguments provided.
 *
 * @param {(string|string[])=} adminPermissionNames - Admin level permission checks. Can be a string or an array. Won't be checked for if null.
 * @param {(string|string[])=} permissionNames - Permissions to check for. Can be a string or an array. Won't be checked for if null.
 * @param {boolean=} organizationLevel - Check for the permission at an organizational level rather than at a site level
 *
 * @example
 * //Check if the user has any ManageLease permissions, either on org or site level
 * usePermissions(null, "ManageLeases", true)
 * @example
 * //Check to see if the user is both Admin and CSAdmin
 * usePermissions(["IsAdmin", "IsCSAdmin"]);
 */
export function usePermissions(
	adminPermissionNames,
	permissionNames,
	organizationLevel
) {
	const {
		state: { currentUser, permissions, selectedOrganization, selectedSite },
	} = useContext(AppContext);

	return userHasPermission(
		{
			currentUser,
			permissions,
			selectedOrganization,
			selectedSite,
		},
		adminPermissionNames,
		permissionNames,
		organizationLevel
	);
}

/**
 * Checks that a user has a permission present on every site in a set.
 *
 * To be used when checking if the user can edit an object which is in a M:M connection to sites.
 *
 * @example
 * const sites = [{SiteID: 50}, {SiteID: 10}];
 * const allowed = useHasPermissionsOnEverySite("ManageLeases", sites);
 *
 * @param {string[]} permissionNames - Can be string array or string.
 * @param {object[]} sites - Array of Site objects to check permissions for.
 * @param {number} sites.SiteID - Only value that needs to be defined for the site objects.
 * @returns {Boolean} true if all permissions are present, false otherwise
 */
export function useHasPermissionsOnEverySite(permissionNames, sites) {
	//Retrieve user, their permission set, the current org being worked on from the context/parent state
	const {
		state: { currentUser, permissions, selectedOrganization },
	} = useContext(AppContext);

	return every(sites, (selectedSite) => {
		return userHasPermission(
			{
				currentUser,
				permissions,
				selectedOrganization,
				selectedSite,
			},
			null,
			permissionNames,
			false
		);
	});
}

export function useHasClientAdmin(clientId) {
	const {
		state: { currentUser, permissions, selectedOrganization, selectedSite },
	} = useContext(AppContext);

	return userHasPermission(
		{
			currentUser,
			permissions,
			selectedOrganization,
			selectedSite,
		},
		null,
		null,
		false,
		clientId || clientId === 0 ? clientId : -1
	);
}

export function useSearchFilter(items = [], searchTerm, searchables) {
	return useMemo(() => {
		if (!searchTerm) return items;

		const search = searchTerm.trim().toLocaleLowerCase();

		return items.filter((item) => {
			for (let searchable of searchables) {
				let value;

				// support complex type searchable field
				if (isObject(searchable)) {
					value = searchable.get(item[searchable.key]);
				} else {
					value = item[searchable];
					if (searchable.indexOf(".") > -1) {
						// support for nested lookups (max 1 level)
						const [key1, key2] = searchable.split(".");
						if (key1 && key2 && item[key1]) {
							value = item[key1][key2];
						}
					}
				}

				if (String(value).trim().toLocaleLowerCase().indexOf(search) > -1) {
					return true;
				}
			}

			return false;
		});
	}, [items, searchTerm, searchables]);
}

/**
 * Fetches data from the specified endpoint(s)
 *
 * @param {Object} initialData - The initial data to return before the fetch call is made
 * @param {Function} endpoint - The api endpoint (should be a function that returns the endpoint)
 * @param {Object} options - Any options to pass to the api endpoint function (should be an array)
 * @param {Array} shouldUpdate - Any options to check for updates in the useEffect hook (should be an array)
 * @param {Function=} cleanUp - An optional function to call in the clean up phase of the useEffect hook
 */
export function useFetchData(
	initialData,
	endpoint,
	options = [],
	shouldUpdate = [],
	cleanUp
) {
	let didCancel = false;

	const [data, setData] = useState(initialData);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [initialFetchComplete, setInitialFetchComplete] = useState(false);

	async function fetchData() {
		if (!didCancel) setIsError(false);
		if (!didCancel) setIsLoading(true);

		try {
			if (isArray(endpoint)) {
				const result = await Promise.all(
					endpoint.map((item, key) => item(...options[key]))
				);

				if (!didCancel) {
					setData(result);
					setInitialFetchComplete(true);
				}
			} else {
				const result = await endpoint(...options);

				if (!didCancel) {
					setData(result);
					setInitialFetchComplete(true);
				}
			}
		} catch (error) {
			if (!didCancel) setIsError(true);
		}

		if (!didCancel) setIsLoading(false);
	}

	useEffect(() => {
		// don't fetch anything if no options were passed
		// this is useful when you need to delay the initial call
		if (options) fetchData();

		return () => {
			didCancel = true;

			if (cleanUp) cleanUp();
		};
	}, shouldUpdate);

	return { data, isLoading, isError, initialFetchComplete };
}

export function useQueryData(query, variables, skip = false, opts = {}) {
	const { state } = useContext(AppContext);
	const { auth = true, fetchPolicy = "network-only" } = opts;
	const { data, error, loading, refetch } = useQuery(query, {
		skip,
		variables,
		fetchPolicy,
		context: {
			auth,
			headers: getContextHeaders(state),
		},
	});

	if (error) {
		// eslint-disable-next-line no-console
		console.error(error);
	}

	return { data: data || {}, isLoading: loading, isError: !!error, refetch };
}

export function useLazyQueryData(query, variables, skip = false) {
	const client = useApolloClient();
	const { state } = useContext(AppContext);

	return async (_variables) => {
		const { data, loading, error, refetch } = await client.query({
			query,
			skip,
			variables: { ...variables, ..._variables },
			context: {
				headers: getContextHeaders(state),
			},
		});

		if (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}

		return { data, isLoading: loading, isError: !!error, refetch };
	};
}

export function useMutateData(mutation, variables, onCompleted, onError) {
	const { state } = useContext(AppContext);
	const [performMutation] = useMutation(mutation, {
		variables,
		context: {
			headers: getContextHeaders(state),
		},
		onCompleted,
		onError,
	});

	return performMutation;
}

export function useImage(url, opts = {}) {
	const defaultState = { image: null, status: "loading" };
	const [state, setState] = useState(defaultState);

	useEffect(() => {
		if (!url) return;
		const img = document.createElement("img");

		const onload = () => {
			setState({ image: img, status: "loaded" });
		};

		const onerror = () => {
			setState({ image: undefined, status: "failed" });
		};

		img.addEventListener("load", onload);
		img.addEventListener("error", onerror);

		if (opts.crossOrigin) img.crossOrigin = opts.crossOrigin;
		if (opts.width) img.width = opts.width;
		if (opts.height) img.height = opts.height;

		img.src = url;

		return () => {
			img.removeEventListener("load", onload);
			img.removeEventListener("error", onerror);
			setState(defaultState);
		};
	}, [url, opts.width, opts.height, opts.crossOrigin]);

	return [state.image, state.status];
}

export function useHasOrganizationViewership() {
	const {
		state: { currentUser, selectedOrganization, permissions, selectedOperator },
	} = useContext(AppContext);
	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		selectedOrganization?.ClientID || selectedOperator?.ClientID
	);

	const orgPermissions = permissions.filter(
		(p) => p.OrganizationID === selectedOrganization?.OrganizationID
	);

	return (
		hasClientAdmin ||
		orgPermissions.some(
			(op) =>
				op.PermissionName === "ViewOrganization" ||
				op.PermissionName === "ViewSite"
		) ||
		isAdmin ||
		selectedOrganization?.UserID === currentUser.UserID
	);
}

export function useCanManageValidations() {
	const {
		state: { selectedOrganization, permissions, selectedOperator },
	} = useContext(AppContext);
	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		selectedOrganization?.ClientID || selectedOperator?.ClientID
	);
	const orgPermissions = permissions.filter(
		(p) => p.OrganizationID === selectedOrganization?.OrganizationID
	);

	return (
		isAdmin ||
		hasClientAdmin ||
		orgPermissions.some((op) =>
			["EditSettings", "ManageValidations"].includes(op.PermissionName)
		)
	);
}
