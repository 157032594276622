import { Redirect, Route, Switch } from "react-router-dom";
import {
	// concat,
	find,
	// flattenDeep,
	map,
	uniqBy,
} from "lodash";
import ErrorBoundary from "../ErrorBoundary";
import React from "react";
// import ValidationAccounts from "../../components/report/validation/ValidationAccounts";
import ValidationBeacons from "../../components/report/validation/ValidationBeacons";
import ValidationHistory from "../../components/report/validation/ValidationHistory";
import ValidationPromotions from "../../components/report/validation/ValidationPromotions";
import { format } from "../../helpers";
import gql from "graphql-tag";
import { useQueryData } from "../../hooks";
import ValidationQRCodes from "../../components/report/validation/ValidationQRCodes";
import AutomaticValidations from "../../components/report/validation/ValidationAuto";

export default function ValidationContainer(props) {
	// let accounts = [];
	// let accountInvitations = [];
	let beacons = [];

	const { data, isLoading, refetch } = useQueryData(
		gql`
			query {
				getValidationAccounts {
					AccountID
					Name
					Address
					BillingAddress
					IsUnmanaged
					Beacons {
						BeaconID
						UniqueID
						Sites {
							SiteID
							Name
						}
					}
				}

				getAccountInvitations {
					AccountInvitationID
					Name
					Email
					Beacons {
						BeaconID
						UniqueID
						Sites {
							SiteID
							Name
						}
					}
				}

				getValidationBeacons {
					BeaconID
					DeviceID
					UniqueID
					Location
					ValidationType
					ValidationValue
					IsSelfValidation
					OrganizationAccessGroupID
					Rates {
						Name
						RateID
						SiteID
					}
					Sites {
						Name
						SiteID
					}
				}
			}
		`
	);

	// if (data.getValidationAccounts) {
	// 	accounts = data.getValidationAccounts.map((account) => {
	// 		const sites = uniqBy(
	// 			flattenDeep(map(account.Beacons, (beacon) => beacon.Sites)),
	// 			"SiteID"
	// 		);
	// 		const _beacons = uniqBy(account.Beacons, "UniqueID");

	// 		return {
	// 			status: account.IsUnmanaged ? "Unmanaged" : "Confirmed",
	// 			accountId: account.AccountID,
	// 			name: account.Name,
	// 			isUnmanaged: account.IsUnmanaged,
	// 			address: account.Address,
	// 			billingAddress: account.BillingAddress,
	// 			sites: sites,
	// 			siteNames: map(sites, (site) => site.Name).join(", "),
	// 			beacons: _beacons,
	// 			beaconNames: map(_beacons, (beacon) => beacon.UniqueID).join(", "),
	// 		};
	// 	});
	// }

	// if (data.getAccountInvitations) {
	// 	let getAccountInvitations = uniqBy(
	// 		data.getAccountInvitations,
	// 		"AccountInvitationID"
	// 	);
	// 	accountInvitations = map(getAccountInvitations, (accountInvitation) => {
	// 		const sites = uniqBy(
	// 			flattenDeep(map(accountInvitation.Beacons, (beacon) => beacon.Sites)),
	// 			"SiteID"
	// 		);
	// 		const _beacons = uniqBy(accountInvitation.Beacons, "UniqueID");
	// 		return {
	// 			status: "Pending",
	// 			accountInvitationId: accountInvitation.AccountInvitationID,
	// 			name: accountInvitation.Name,
	// 			email: accountInvitation.Email,
	// 			sites: sites,
	// 			siteNames: map(sites, (site) => site.Name).join(", "),
	// 			beacons: _beacons,
	// 			beaconNames: map(_beacons, (beacon) => beacon.UniqueID).join(", "),
	// 		};
	// 	});
	// }

	if (data.getValidationBeacons) {
		beacons = uniqBy(
			data.getValidationBeacons.map(({ Rates, ...beacon }) => ({
				Rates,
				...beacon,
				rate: format.validation(
					beacon.ValidationValue,
					beacon.ValidationType,
					Rates,
					beacon.Sites
				),
				siteName: [...new Set(beacon.Sites.flatMap((site) => site.Name))].join(
					", "
				),
				sites: beacon.Sites
					? map(beacon.Sites, (site) => {
							let _site = {};
							const rate = find(Rates, (_rate) => _rate.SiteID === site.SiteID);

							if (rate) {
								_site = {
									id: site.SiteID,
									name: site.Name,
									rateId: rate.RateID,
								};
							} else {
								_site = { id: site.SiteID, name: site.Name };
							}

							return _site;
					  })
					: [],
			})),
			"UniqueID"
		);
	}

	return (
		<ErrorBoundary>
			<Switch>
				<Route
					path={`${props.match.url}/history`}
					render={(_props) => <ValidationHistory {...props} {..._props} />}
				/>
				<Route
					path={`${props.match.url}/beacons`}
					render={(_props) => (
						<ValidationBeacons
							{...props}
							{..._props}
							beacons={beacons}
							isLoading={isLoading}
							refetch={refetch}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/qr-codes`}
					render={(_props) => (
						<ValidationQRCodes {...props} {..._props} isLoading={isLoading} />
					)}
				/>
				{/* <Route
					path={`${props.match.url}/accounts`}
					render={(_props) => (
						<ValidationAccounts
							{...props}
							{..._props}
							accounts={concat(accounts, accountInvitations)}
							isLoading={isLoading}
							beacons={beacons}
							refetch={refetch}
						/>
					)}
				/> */}
				<Route
					path={`${props.match.url}/promotions`}
					render={(_props) => (
						<ValidationPromotions
							{...props}
							{..._props}
							isLoading={isLoading}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/auto-validation`}
					render={(_props) => (
						<AutomaticValidations
							{...props}
							{..._props}
							isLoading={isLoading}
						/>
					)}
				/>
				<Redirect to={`${props.match.url}/history`} />
			</Switch>
		</ErrorBoundary>
	);
}
