import { Redirect, Route, Switch } from "react-router-dom";
import ActivityContainer from "./ActivityContainer";
import BillingContainer from "./BillingContainer";
import BookingsContainer from "./BookingsContainer";
import BrandsContainer from "./BrandsContainer";
import Breadcrumb from "../../components/layout/Breadcrumb";
import ContextMenu from "../../components/menu/ContextMenu";
import ContextMenuLink from "../../components/menu/ContextMenuLink";
import DashboardContainer from "./DashboardContainer";
import ErrorBoundary from "../ErrorBoundary";
import HistoryContainer from "./HistoryContainer";
import IntegrationContainer from "./IntegrationContainer";
import PermissionsContainer from "./permissions";
import RatesContainer from "./RatesContainer";
import React, { useContext } from "react";
import RevenueContainer from "./RevenueContainer";
import SettingsContainer from "./SettingsContainer";
import SignageContainer from "./SignageContainer";
import SiteContainer from "../site/SiteContainer";
import SpaceContainer from "./SpaceContainer";
import TransientContainer from "./TransientContainer";
import TransientContainerV2 from "./TransientContainerV2";
import UsersContainer from "./UsersContainer";
import ValidationContainer from "./ValidationContainer";
import {
	usePermissions,
	useHasClientAdmin,
	useHasOrganizationViewership,
} from "../../hooks";
import { useTranslation } from "react-i18next";
import EnforcementViolationsContainer from "./EnforcementViolationsContainer";
import { AppContext } from "../../context/app-context";
import EnforcementRulesContainer from "./EnforcementRulesContainer";
import OccupancyContainer from "./OccupancyContainer";
import EventsContainer from "./EventsContainer";
import SessionsTable from "../search/SessionsTable";

function PageWrapper(props) {
	return (
		<ErrorBoundary>
			{props.menu}
			<Breadcrumb
				items={[
					{
						text: props.selectedOrganization
							? props.selectedOrganization.Name
							: "",
						onClick: () => props.history.push(props.match.url),
					},
				]}
			/>
			{props.body}
		</ErrorBoundary>
	);
}

function Permissions(props) {
	const { t: translate } = useTranslation();

	return (
		<Route
			path={props.path}
			render={(routeProps) => (
				<PageWrapper
					{...props}
					menu={
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="roles"
								path={`${props.match.url}/permissions/roles`}
								name={translate("ContextMenu.Organization.Permissions.Roles")}
								location={props.location}
							/>
							<ContextMenuLink
								key="groups"
								path={`${props.match.url}/permissions/groups`}
								name={translate("ContextMenu.Organization.Permissions.Groups")}
								location={props.location}
							/>
						</ContextMenu>
					}
					body={<PermissionsContainer {...props} {...routeProps} />}
				/>
			)}
		/>
	);
}

function Enforcement(props) {
	if (!props.selectedOrganization?.HasEnforcement) {
		return null;
	}

	return (
		<Route
			path={props.path}
			render={(routeProps) => (
				<PageWrapper
					{...props}
					menu={
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="violations"
								path={`${props.match.url}/enforcement/violations`}
								name={"Violation Events"}
								location={props.location}
							/>
							<ContextMenuLink
								key="rules"
								path={`${props.match.url}/enforcement/rules`}
								name={"Rules"}
								location={props.location}
							/>
						</ContextMenu>
					}
					body={
						<Switch>
							<Route path={`${props.match.url}/enforcement/violations`}>
								<EnforcementViolationsContainer {...props} {...routeProps} />
							</Route>
							<Route path={`${props.match.url}/enforcement/rules`}>
								<EnforcementRulesContainer {...props} {...routeProps} />
							</Route>
							<Redirect to={`${props.match.url}/enforcement/violations`} />
						</Switch>
					}
				/>
			)}
		/>
	);
}

function Activity(props) {
	return (
		<Route
			path={props.path}
			render={(routeProps) => (
				<PageWrapper
					{...props}
					body={<ActivityContainer {...props} {...routeProps} />}
				/>
			)}
		/>
	);
}

function Validations(props) {
	const { t: translate } = useTranslation();

	return (
		<Route
			path={props.path}
			render={(routeProps) => (
				<PageWrapper
					{...props}
					menu={
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="history"
								path={`${props.match.url}/validation/history`}
								name={translate("ContextMenu.Organization.Validation.History")}
								location={props.location}
							/>
							<ContextMenuLink
								key="beacons"
								path={`${props.match.url}/validation/beacons`}
								name={translate("ContextMenu.Organization.Validation.Beacons")}
								location={props.location}
							/>
							<ContextMenuLink
								key="qr-codes"
								path={`${props.match.url}/validation/qr-codes`}
								name={translate("ContextMenu.Organization.Validation.QRCodes")}
								location={props.location}
							/>
							{/* <ContextMenuLink
								key="accounts"
								path={`${props.match.url}/validation/accounts`}
								name={translate("ContextMenu.Organization.Validation.Accounts")}
								location={props.location}
							/> */}
							<ContextMenuLink
								key="promotions"
								path={`${props.match.url}/validation/promotions`}
								name={translate(
									"ContextMenu.Organization.Validation.Promotions"
								)}
								location={props.location}
							/>
							<ContextMenuLink
								key="auto-validation"
								path={`${props.match.url}/validation/auto-validation`}
								name={translate(
									"ContextMenu.Organization.Validation.AutoValidation"
								)}
								location={props.location}
							/>
						</ContextMenu>
					}
					body={<ValidationContainer {...props} {...routeProps} />}
				/>
			)}
		/>
	);
}

export default function OrganizationContainer(props) {
	const { t: translate } = useTranslation();
	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		props.selectedOrganization?.ClientID
	);

	const hasEnforcementAccess = usePermissions(null, "EnforcementAccess", true);
	const hasValidationAccess = usePermissions(null, "ManageValidations", true);
	const hasOrganizationViewership = useHasOrganizationViewership();

	const {
		state: { spaceAccountOwnerOnly },
	} = useContext(AppContext);

	if (spaceAccountOwnerOnly) {
		return (
			<Switch>
				<Permissions path={`${props.match.url}/permissions`} {...props} />
				<Redirect to={`${props.match.url}/permissions`} />
			</Switch>
		);
	}

	if (!hasOrganizationViewership && hasEnforcementAccess) {
		return (
			<Switch>
				<Enforcement path={`${props.match.url}/enforcement`} {...props} />
				<Activity path={`${props.match.url}/activity`} {...props} />
				<Redirect to={`${props.match.url}/enforcement`} />
			</Switch>
		);
	}

	if (!hasOrganizationViewership && hasValidationAccess) {
		const path = `${props.match.url}/validation`;
		return (
			<Switch>
				<Validations path={path} {...props} />
				<Redirect to={path} />
			</Switch>
		);
	}

	if (!hasOrganizationViewership) {
		return <Switch />;
	}

	return (
		<Switch>
			<Route
				path={`${props.match.url}/user-information`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<SessionsTable {...props} {...routeProps} />}
					/>
				)}
			/>

			<Route
				path={`${props.match.url}/site/:siteId`}
				render={(routeProps) =>
					props.availableSites.length ? (
						<ErrorBoundary>
							<SiteContainer {...props} {...routeProps} />
						</ErrorBoundary>
					) : null
				}
			/>

			<Route
				path={`${props.match.url}/dashboard`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<DashboardContainer {...props} {...routeProps} />}
					/>
				)}
			/>

			<Permissions path={`${props.match.url}/permissions`} {...props} />

			<Route
				path={`${props.match.url}/revenue`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<RevenueContainer {...props} {...routeProps} />}
					/>
				)}
			/>
			<Route
				path={`${props.match.url}/users`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<UsersContainer {...props} {...routeProps} />}
					/>
				)}
			/>
			<Activity path={`${props.match.url}/activity`} {...props} />
			<Route
				path={`${props.match.url}/transient`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						menu={
							<ContextMenu menuCollapsed={props.menuCollapsed}>
								<ContextMenuLink
									key="sessions"
									path={`${props.match.url}/transient/sessions`}
									name={translate(
										"ContextMenu.Organization.Transient.Sessions"
									)}
									location={props.location}
								/>
								{/* {!!props.availableSites.filter((site) => site.HasANPR)
									.length && (
									<>
										<ContextMenuLink
											key="permitted"
											path={`${props.match.url}/transient/permitted`}
											name={translate(
												"ContextMenu.Organization.Transient.Permitted"
											)}
											location={props.location}
										/>
										<ContextMenuLink
											key="unpermitted"
											path={`${props.match.url}/transient/unpermitted`}
											name={translate(
												"ContextMenu.Organization.Transient.Unpermitted"
											)}
											location={props.location}
										/>
									</>
								)} */}
							</ContextMenu>
						}
						body={
							<Switch>
								<Route path={`${props.match.url}/transient/sessions`}>
									<TransientContainer {...props} {...routeProps} />
								</Route>
								{props.availableSites.filter((site) => site.HasANPR).length && [
									<Route
										key={"permitted"}
										path={`${props.match.url}/transient/permitted`}
									>
										<TransientContainerV2 {...props} {...routeProps} />
									</Route>,
									<Route
										key={"unpermitted"}
										path={`${props.match.url}/transient/unpermitted`}
									>
										<TransientContainerV2 {...props} {...routeProps} />
									</Route>,
								]}
								<Redirect to={`${props.match.url}/transient/sessions`} />
							</Switch>
						}
					/>
				)}
			/>
			<Route
				path={`${props.match.url}/bookings`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<BookingsContainer {...props} {...routeProps} />}
					/>
				)}
				event-management
			/>
			<Route
				path={`${props.match.url}/event-management`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						menu={
							<ContextMenu menuCollapsed={props.menuCollapsed}>
								<ContextMenuLink
									key="events"
									path={`${props.match.url}/event-management/events`}
									name={translate(
										"ContextMenu.Organization.EventManagement.Events"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="passes"
									path={`${props.match.url}/event-management/passes`}
									name={translate(
										"ContextMenu.Organization.EventManagement.EventPasses"
									)}
									location={props.location}
								/>
							</ContextMenu>
						}
						body={<EventsContainer {...props} {...routeProps} />}
					/>
				)}
			/>
			<Validations path={`${props.match.url}/validation`} {...props} />
			<Route
				path={`${props.match.url}/rates`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						menu={
							<ContextMenu menuCollapsed={props.menuCollapsed}>
								<ContextMenuLink
									key="transient"
									path={`${props.match.url}/rates/transient`}
									name={translate(
										"ContextMenu.Organization.Rates.TransientRates"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="monthly"
									path={`${props.match.url}/rates/monthly`}
									name={translate(
										"ContextMenu.Organization.Rates.BookingRates"
									)}
									location={props.location}
								/>
								{isAdmin || hasClientAdmin ? (
									<ContextMenuLink
										key="products"
										path={`${props.match.url}/rates/products`}
										name={translate("ContextMenu.Organization.Rates.Products")}
										location={props.location}
									/>
								) : null}
							</ContextMenu>
						}
						body={<RatesContainer {...props} {...routeProps} />}
					/>
				)}
			/>

			<Route
				path={`${props.match.url}/space-management`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						menu={
							<ContextMenu menuCollapsed={props.menuCollapsed}>
								<ContextMenuLink
									key="spaces"
									path={`${props.match.url}/space-management/spaces`}
									name={translate(
										"ContextMenu.Organization.SpaceManagement.Spaces"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="bays"
									path={`${props.match.url}/space-management/bays`}
									name={translate(
										"ContextMenu.Organization.SpaceManagement.Bays"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="accounts"
									path={`${props.match.url}/space-management/accounts`}
									name={translate(
										"ContextMenu.Organization.SpaceManagement.Accounts"
									)}
									location={props.location}
								/>
							</ContextMenu>
						}
						body={<SpaceContainer {...props} {...routeProps} />}
					/>
				)}
			/>

			<Route
				path={`${props.match.url}/history`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<HistoryContainer {...props} {...routeProps} />}
					/>
				)}
			/>
			<Route
				path={`${props.match.url}/billing`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<BillingContainer {...props} {...routeProps} />}
					/>
				)}
			/>
			<Route
				path={`${props.match.url}/integrations`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						menu={
							<ContextMenu menuCollapsed={props.menuCollapsed}>
								<ContextMenuLink
									key="integrations"
									path={`${props.match.url}/integrations`}
									name={translate(
										"ContextMenu.Organization.Integration.Integrations"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="api-keys"
									path={`${props.match.url}/integrations/api-keys`}
									name={translate(
										"ContextMenu.Organization.Integration.ApiKeys"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="requests"
									path={`${props.match.url}/integrations/requests`}
									name={translate(
										"ContextMenu.Organization.Integration.Requests"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="variables"
									path={`${props.match.url}/integrations/variables`}
									name={translate(
										"ContextMenu.Organization.Integration.Variables"
									)}
									location={props.location}
								/>
							</ContextMenu>
						}
						body={
							<IntegrationContainer
								{...props}
								{...routeProps}
								context="Client"
							/>
						}
					/>
				)}
			/>
			<Route
				path={`${props.match.url}/brands`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<BrandsContainer {...props} {...routeProps} />}
					/>
				)}
			/>

			<Route
				path={`${props.match.url}/signage`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						menu={
							<ContextMenu menuCollapsed={props.menuCollapsed}>
								<ContextMenuLink
									key="generate"
									path={`${props.match.url}/signage/generate`}
									name={translate("ContextMenu.Organization.Signage.Generate")}
									location={props.location}
								/>
								<ContextMenuLink
									key="unique-qr-codes"
									path={`${props.match.url}/signage/unique-qr-codes`}
									name={translate(
										"ContextMenu.Organization.Signage.UniqueQRCodes"
									)}
									location={props.location}
								/>
								<ContextMenuLink
									key="history"
									path={`${props.match.url}/signage/history`}
									name={translate("ContextMenu.Organization.Signage.History")}
									location={props.location}
								/>
							</ContextMenu>
						}
						body={<SignageContainer {...props} {...routeProps} />}
					/>
				)}
			/>

			<Route
				path={`${props.match.url}/settings`}
				render={(routeProps) => (
					<PageWrapper
						{...props}
						body={<SettingsContainer {...props} {...routeProps} />}
					/>
				)}
			/>
			<Enforcement path={`${props.match.url}/enforcement`} {...props} />

			{props.selectedOrganization?.HasOccupancy && (
				<Route
					path={`${props.match.url}/occupancy`}
					render={(routeProps) => (
						<PageWrapper
							{...props}
							body={<OccupancyContainer {...props} {...routeProps} />}
						/>
					)}
				/>
			)}

			<Redirect to={`${props.match.url}/dashboard`}></Redirect>
		</Switch>
	);
}
