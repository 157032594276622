import { CONDITIONS } from "./constants";

function bodyToObject(body) {
	try {
		return JSON.parse(body) || {};
	} catch (e) {
		return {};
	}
}

function conditionIsUnary(operator) {
	return [
		CONDITIONS.IS_EMPTY,
		CONDITIONS.IS_TRUTHY,
		CONDITIONS.NOT_SET,
	].includes(operator);
}

export { bodyToObject, conditionIsUnary };
