import React, { useState } from "react";
import TableLayoutBeta from "../../components/layout/TableLayoutBeta";
import SearchBox from "../../components/layout/SearchBox";
import Button from "../../components/layout/Button";
import { useSearchParkingSessions, useSearchUserLeases } from "./searchHooks";
import Card from "../../components/layout/Card";
import moment from "moment";
import DropdownMenu from "../../components/layout/DropdownMenu";
import { MoreHorizontal } from "react-feather";
import TransactionDetails from "../../components/details-pages/users/TransactionDetails";
import PageTitle from "../../components/layout/PageTitle";
import PageActions, { LeftActions } from "../../components/layout/PageActions";
import { defaultTimeFormat } from "../../helpers/constants";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";

function formatCurrency(currency, amount) {
	const numberFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: currency ? currency : "USD",
	});

	if (!amount) {
		return "";
	}

	return numberFormatter.format(amount);
}

const SearchBoxStyles = {
	maxWidth: "200px",
};

const SearchBoxStylesWide = {
	maxWidth: "300px",
};

export default function SessionsTable() {
	const [email, setEmail] = useState(null);
	const [numberplate, setNumberplate] = useState(null);
	const [transactionId, setTransactionId] = useState(null);
	// const [startTimestamp, setStartTimestamp] = useState(null);
	// const [endTimestamp, setEndTimestamp] = useState(null);
	const [searchArgs, setSearchArgs] = useState({});

	const [shouldShowTable, setShouldHideTable] = useState(false);
	const [
		shouldShowTransactionDetailsModal,
		setShouldShowTransactionDetailsModal,
	] = useState(false);
	const [leaseDetailsForTransactionModal, setLeaseDetailsForTransactionModal] =
		useState(null);

	const [
		sessionDetailsForTransactionModal,
		setSessionDetailsForTransactionModal,
	] = useState(null);

	const handleOpenTransactionModalForLease = (lease) => {
		setLeaseDetailsForTransactionModal(lease);
		setShouldShowTransactionDetailsModal(true);
	};

	const handleOpenTransactionModalForSession = (session) => {
		setSessionDetailsForTransactionModal(session);
		setShouldShowTransactionDetailsModal(true);
	};

	const handleCloseTransactionModal = () => {
		setShouldShowTransactionDetailsModal(false);
		setSessionDetailsForTransactionModal(null);
		setLeaseDetailsForTransactionModal(null);
	};

	const { data: sessionData, isLoading: isLoadingSessions } =
		useSearchParkingSessions(searchArgs);

	const { data: userLeaseData, isLoading: isLoadingUserLeases } =
		useSearchUserLeases(searchArgs);

	const handleSearchClick = () => {
		setSearchArgs({
			email,
			numberplate,
			transactionId,
		});
		setShouldHideTable(true);
	};

	const handleSearchOnEnter = (e) => {
		if (e.key === "Enter") handleSearchClick();
	};

	async function handleEmailChange(value) {
		setEmail(value);
	}

	async function handleNumberplateChange(value) {
		setNumberplate(value);
	}

	async function handleTransactionIdChange(value) {
		setTransactionId(value);
	}

	const LeasesColumns = [
		{
			id: "SiteName",
			Header: "Site",
			accessor: "Site.Name",
			fixedWidth: 150,
		},
		{
			id: "StartDateTime",
			Header: "Start Time",
			accessor: "StartDateTime",
			fixedWidth: 200,
			Cell: (cellProps) => {
				return moment(cellProps.value, "YYYYMMDDHHmm").format(
					defaultTimeFormat
				);
			},
		},
		{
			id: "EndDateTime",
			Header: "End Time",
			accessor: "EndDateTime",
			fixedWidth: 200,
			Cell: (cellProps) => {
				return moment(cellProps.value, "YYYYMMDDHHmm").format(
					defaultTimeFormat
				);
			},
		},
		{
			id: "Numberplate",
			Header: "Plate",
			accessor: "Numberplate",
			fixedWidth: 200,
		},
		// {
		// 	id: "Amount",
		// 	Header: "Amount",
		// 	accessor: "LeasePayments",
		// 	fixedWidth: 200,
		// 	Cell: (cellProps) => {
		// 		const fee = cellProps?.value[0]?.Fee;
		// 		const currency = cellProps.row.original?.Site?.Currency;
		// 		return formatCurrency(currency, fee);
		// 	},
		// },
		{
			id: "Details",
			Header: "",
			accessor: null,
			fixedWidth: 50,
			Cell: (cellProps) => {
				const session = cellProps.row.original;
				return (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="lease-details"
								onClick={() => handleOpenTransactionModalForLease(session)}
							>
								Transaction Details
							</div>,
						]}
					/>
				);
			},
		},
	];

	const ParkingSessionColumns = [
		{
			id: "SiteName",
			Header: "Site",
			accessor: "Site.Name",
			fixedWidth: 150,
		},
		{
			id: "EntryTimestamp",
			Header: "Start Time",
			accessor: (d) =>
				moment(d.EntryTimestamp, "hh:mm A [on] DD MMM YYYY").unix(),
			Cell: ({ row: { original: d } }) => d.EntryTimestamp,
			fixedWidth: 200,
		},
		{
			id: "ExitTimestamp",
			Header: "End Time",
			accessor: (d) =>
				moment(d.ExitTimestamp, "hh:mm A [on] DD MMM YYYY").unix(),
			Cell: ({ row: { original: d } }) => d.ExitTimestamp,
			fixedWidth: 200,
		},
		{
			id: "Numberplate",
			Header: "Plate",
			accessor: "NumberplateValue",
			fixedWidth: 200,
		},
		{
			id: "Amount",
			Header: "Amount",
			accessor: "FinalFee",
			fixedWidth: 200,
			Cell: ({ row: { original } }) => {
				const { Site, WasVoided, FinalFee } = original;
				const { Currency } = Site;
				if (WasVoided) {
					return "Void";
				}
				return formatCurrency(Currency, FinalFee);
			},
		},
		{
			id: "Details",
			Header: "",
			accessor: null,
			fixedWidth: 50,
			Cell: (cellProps) => {
				const session = cellProps.row.original;
				return (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="session-details"
								onClick={() => handleOpenTransactionModalForSession(session)}
							>
								Session Details
							</div>,
						]}
					/>
				);
			},
		},
	];

	if (shouldShowTransactionDetailsModal) {
		const siteId =
			leaseDetailsForTransactionModal?.Site?.SiteID ||
			sessionDetailsForTransactionModal?.Site?.SiteID;

		// these are wrong?
		const leaseId = leaseDetailsForTransactionModal?.UserLeaseID;
		const sessionId = sessionDetailsForTransactionModal?.ParkingSessionID;

		return (
			<TransactionDetails
				siteId={siteId}
				session={sessionId ? { ParkingSessionID: sessionId } : null}
				lease={leaseId ? { UserLeaseID: leaseId } : null}
				productPurchaseId={null}
				onClose={handleCloseTransactionModal}
			/>
		);
	}

	return (
		<>
			<PageTitle>Search Transactions</PageTitle>
			<PageActions>
				<LeftActions style={{ display: "flex", gap: "16px" }}>
					<SearchBox
						style={SearchBoxStylesWide}
						value={email}
						onChange={handleEmailChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"Email"}
					/>
					<SearchBox
						style={SearchBoxStyles}
						value={numberplate}
						onChange={handleNumberplateChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"Plate"}
					/>
					<SearchBox
						style={{ ...SearchBoxStyles, minWidth: 280 }}
						value={transactionId}
						onChange={handleTransactionIdChange}
						onKeyUp={handleSearchOnEnter}
						placeholder={"Payment processor reference"}
					/>
					<Button
						style={SearchBoxStyles}
						color="blue"
						onClick={handleSearchClick}
					>
						Search
					</Button>
				</LeftActions>
			</PageActions>

			<p style={{ margin: "0 16px" }}>
				*The search function works only for exact matches. Ensure that you enter
				the correct spelling or IDs for the best results.
			</p>

			{shouldShowTable && (
				<Card>
					<h1>Parking Sessions</h1>
					{isLoadingSessions ? (
						<LoadingPlaceholder />
					) : (
						<TableLayoutBeta
							data={sessionData?.searchParkingSessions}
							filterOptions={[]}
							columns={ParkingSessionColumns}
							showResultsLength={true}
							defaultSortBy={[
								{
									id: "EntryTimestamp",
									desc: true,
								},
							]}
						/>
					)}
				</Card>
			)}

			{shouldShowTable && (
				<Card>
					<h1>Bookings</h1>
					{isLoadingUserLeases ? (
						<LoadingPlaceholder />
					) : (
						<TableLayoutBeta
							data={userLeaseData?.searchUserLeases}
							filterOptions={[]}
							columns={LeasesColumns}
							showResultsLength={true}
							defaultSortBy={[
								{
									id: "StartDateTime",
									desc: true,
								},
							]}
						/>
					)}
				</Card>
			)}
		</>
	);
}
