import { MoreHorizontal, Plus } from "react-feather";
import React, { useContext, useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import { usePermissions } from "../../../hooks";
import Button from "../../layout/Button";
import DropdownMenuBeta from "../../../components/layout/DropdownMenuBeta";
import ReportWrapperBeta from "../ReportWrapperBeta";
import { formatFee } from "../../../helpers/format";
import { format } from "../../../helpers";
import ValidationWizard from "../../wizards/validation-wizard";
import {
	VALIDATION_TYPE_OPTIONS,
	VALIDATION_TYPES,
	VALIDATION_WIZARD_TYPE,
} from "../../../helpers/constants";
import { AppContext } from "../../../context/app-context";
import { useGetValidationConfigurationForOrganization } from "../../../api/validations/validations";

const defaultSorting = [
	{
		id: "CreatedOn",
		desc: false,
	},
];

export default function AutomaticValidations(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			search: "",
		},
		validationConfiguration: "",
		wizardOpen: false,
		wizardMode: null,
		submitting: false,
	});

	const {
		state: { availableSites },
	} = useContext(AppContext);

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;
	const validationSites = availableSites
		.filter((site) => site.HasValidation)
		.map((site) => ({
			id: site.SiteID,
			name: site.Name,
		}));

	const [paginate, setPaginate] = useState(paginatedState);
	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = (mode, validationConfiguration) => {
		setState((_state) => ({
			..._state,
			validationConfiguration,
			wizardOpen: true,
			wizardMode: mode,
		}));
	};

	const isAdmin = usePermissions("IsAdmin");
	const canEditSettings = usePermissions(null, "EditSettings", true);

	const getValue = (validationConfiguration) => {
		const {
			ValidationMethod,
			EmissionsRanges,
			ValidationType,
			ValidationValue,
			Sites,
			Rates,
			LeaseRates,
		} = validationConfiguration;

		if (ValidationMethod === "Automatic" && EmissionsRanges.length > 0) {
			const getRangeDescription = (
				LowerLimit,
				UpperLimit,
				RateID,
				LeaseRateIDs,
				EmissionRangeValue
			) => {
				if (ValidationType === VALIDATION_TYPES.RATE) {
					return (
						`Emissions Range: ${LowerLimit}-${UpperLimit}:\n` +
						(RateID
							? `Transient Rate - ${
									Rates?.find((r) => r.RateID === RateID)?.Name ||
									"Default Rate"
							  }\n`
							: "") +
						(LeaseRateIDs?.length
							? `Booking Rate - ${
									LeaseRates?.filter((r) =>
										LeaseRateIDs.includes(r.LeaseRateID)
									)
										.map((r) => r.Name)
										.join(", ") || "Default Rate"
							  }`
							: "")
					);
				}
				return `Emissions Range: ${LowerLimit}-${UpperLimit} - ${formatFee(
					{ fee: EmissionRangeValue },
					ValidationType
				)}`;
			};

			// Group emissions ranges by SiteID
			const groupedBySite = EmissionsRanges.reduce(
				(
					acc,
					{
						SiteID,
						RateID,
						LeaseRateIDs,
						LowerLimit,
						UpperLimit,
						ValidationValue: EmissionRangeValue,
					}
				) => {
					acc[SiteID] = acc[SiteID] || [];
					const rangeDescription = getRangeDescription(
						LowerLimit,
						UpperLimit,
						RateID,
						LeaseRateIDs,
						EmissionRangeValue
					);
					acc[SiteID].push(rangeDescription);
					return acc;
				},
				{}
			);

			// Display the ranges for the single site
			const ranges = groupedBySite[Object.keys(groupedBySite)[0]];

			return (
				<ul>
					{ranges.map((range, index) => (
						<li style={{ whiteSpace: "pre-wrap" }} key={index}>
							{range}
						</li>
					))}
				</ul>
			);
		}

		if (ValidationType === VALIDATION_TYPES.RATE) {
			const rate = Rates.find((r) => r.SiteID === Sites[0].SiteID);
			return rate ? rate.Name : "Unknown Rate";
		}

		return formatFee({ fee: ValidationValue }, ValidationType);
	};

	const columns = [
		{
			id: "Location",
			Header: "Location",
			accessor: (d) => d.Location,
			highlightCell: (cellProps) => cellProps.row.original.Location || "",
		},
		{
			id: "ValidationMethod",
			Header: "Validate On",
			accessor: (d) => d.ValidationMethod,
		},
		{
			id: "ValidationType",
			Header: "Type",
			accessor: (d) => d.ValidationType,
		},
		{
			id: "ValidationValue",
			Header: "Value",
			accessor: (d) => getValue(d),
			disableSortBy: true,
		},
		{
			id: "Sites",
			Header: "Site",
			accessor: (d) => d.Sites?.map((site) => site.Name).join(", "),
			disableSortBy: true,
		},
		{
			id: "CreatedOn",
			Header: "CreatedOn",
			accessor: (d) => d.CreatedOn,
			Cell: (cellProps) =>
				format.localDate(cellProps.row.original.CreatedOn, "X", true),
		},
	];

	const canManageValidation = (_props) => [
		...columns,
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => {
				return (
					<DropdownMenuBeta
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="update"
								onClick={() =>
									_props.openWizard("update", cellProps.row.original)
								}
							>
								Edit
							</div>,
							<div
								key="delete"
								onClick={() =>
									_props.openWizard("delete", cellProps.row.original)
								}
							>
								Delete
							</div>,
						]}
					/>
				);
			},
			resizable: false,
			width: 50,
		},
	];

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const {
		data: { getValidationConfigurationForOrganization },
		isLoading,
		refetch,
	} = useGetValidationConfigurationForOrganization(paginate, skipQuery);

	const trueLength = getValidationConfigurationForOrganization?.trueLength || 0;
	const validationConfigurations =
		getValidationConfigurationForOrganization?.validationConfigurations?.map(
			(validationConfiguration) => ({
				...validationConfiguration,
				sites: validationConfiguration.Sites?.map((s) => {
					const site = {
						id: s.SiteID,
						name: s.Name,
					};
					const rate = validationConfiguration.Rates?.find(
						(r) => r.SiteID === s.SiteID
					);
					if (rate) {
						site.rateId = rate.RateID;
					}
					return site;
				}),
			})
		) || [];

	const validationSiteIds = validationConfigurations
		.map((item) => item.Sites.map((site) => site.SiteID))
		.flat();

	const availableValidationSites = validationSites.filter(
		(site) => !validationSiteIds.includes(site.id)
	);

	async function refetchData() {
		await refetch();
		setState((_state) => ({ ..._state, submitting: false }));
	}

	if (state.wizardOpen) {
		const [fixed, percentage, rate] = VALIDATION_TYPE_OPTIONS;
		return (
			<ValidationWizard
				type={VALIDATION_WIZARD_TYPE.AUTO_VALIDATION}
				mode={state.wizardMode}
				organizationId={organizationId}
				validationTypeOptions={[fixed, percentage, rate]}
				rateData={validationConfigurations.flatMap((vc) => vc.Rates)}
				availableSites={availableValidationSites}
				close={() => {
					setState((_state) => ({
						..._state,
						wizardOpen: false,
					}));
					refetchData();
				}}
				validationConfiguration={state.validationConfiguration}
			/>
		);
	}

	return (
		<ReportWrapperBeta
			{...props}
			title="Auto Validations"
			data={validationConfigurations}
			columns={
				isAdmin || canEditSettings
					? canManageValidation({ openWizard })
					: columns
			}
			defaultSortBy={defaultSorting}
			updateOptions={updateOptions}
			loading={state.submitting}
			rightActions={
				isAdmin || canEditSettings ? (
					<>
						<Button key="submit" color="blue" onClick={() => openWizard("add")}>
							<Plus size={20} /> Add Auto Validations
						</Button>
					</>
				) : null
			}
			dataTrueLength={trueLength}
			cursorColumn="ValidationConfigurationID"
			paginationIsLoading={isLoading || skipQuery || props.isLoading}
			pageSize={state.pageSize}
			previousEvent={getValidationConfigurationForOrganization?.previousEvent}
			searchTokens={paginate.searchTokens}
			showSitePicker={false}
			showDateRangePicker={false}
			fetchPaginatedData={fetchPaginatedData}
		/>
	);
}
