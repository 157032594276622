import gql from "graphql-tag";
import { useQueryData } from "../../hooks";

export const useSearchUsers = (state) => {
	const { data, isLoading } = useQueryData(
		gql`
			query (
				$userId: Int
				$email: String
				$firstName: String
				$lastName: String
				$phone: String
				$numberplate: String
			) {
				getUsersBySearch(
					userId: $userId
					email: $email
					firstName: $firstName
					lastName: $lastName
					phone: $phone
					numberplate: $numberplate
				) {
					UserID
					Email
					FirstName
					LastName
					Phone
					IsAdmin
					Numberplates {
						NumberplateID
						Numberplate
						CountryCode
						StateCode
						ANPREnabled
						Vehicle {
							VehicleID
							MakeAndModel
							Color
							EngineCapacity
							FuelType
							IsActive
						}
					}
				}
			}
		`,
		{
			userId: state.userId,
			email: state.email,
			firstName: state.firstName,
			lastName: state.lastName,
			phone: state.phone,
			numberplate: state.numberplate,
		},
		!Object.values(state).some((val) => !!val)
	);

	return { data, isLoading };
};

export const useSearchParkingSessions = (state) => {
	const { data, isLoading } = useQueryData(
		gql`
			query ($email: String, $numberplate: String, $transactionId: String) {
				searchParkingSessions(
					email: $email
					numberplate: $numberplate
					transactionId: $transactionId
				) {
					ParkingSessionID
					EntryTimestamp
					ExitTimestamp
					FinalFee
					IsWebPaySession
					NumberplateValue
					WasVoided
					Site {
						SiteID
						OrganizationID
						Name
						Timezone
						Currency
					}
					User {
						UserID
						FirstName
						LastName
					}
				}
			}
		`,
		{
			email: state.email,
			numberplate: state.numberplate,
			transactionId: state.transactionId,
			userId: state.userId,
		},
		!Object.values(state).some((val) => !!val)
	);

	return { data, isLoading };
};

export const useSearchUserLeases = (state) => {
	const { data, isLoading } = useQueryData(
		gql`
			query ($email: String, $numberplate: String, $transactionId: String) {
				searchUserLeases(
					email: $email
					numberplate: $numberplate
					transactionId: $transactionId
				) {
					UserLeaseID
					StartDateTime
					EndDateTime
					Numberplate
					Site {
						SiteID
						OrganizationID
						Name
						Timezone
						Currency
					}
				}
			}
		`,
		{
			email: state.email,
			numberplate: state.numberplate,
			transactionId: state.transactionId,
			userId: state.userId,
		},
		!Object.values(state).some((val) => !!val)
	);

	return { data, isLoading };
};
