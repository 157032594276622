import * as installationApi from "../../api/installation";
import { MoreHorizontal, UserPlus } from "react-feather";
import React, { useState } from "react";
import { filter, intersection } from "lodash";
import {
	useFetchData,
	useHasOrganizationViewership,
	usePermissions,
	useSearchFilter,
} from "../../hooks";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import { DURATION_TYPES } from "../../helpers/constants";
import DropdownMenu from "../../components/layout/DropdownMenu";
import EditBookingWizard from "../../components/wizards/edit-booking-wizard/EditBookingWizard";
import FlexWrapper from "../../components/layout/FlexWrapper";
import Label from "../../components/layout/Label";
import LeaseWizard from "../../components/wizards/lease-wizard/LeaseWizard";
import ReportPicker from "../../components/report/ReportPicker";
import ReportWrapper from "../../components/report/ReportWrapper";
import StatCard from "../../components/layout/StatCard";
import TabSelector from "../../components/layout/TabSelector";
import TransactionDetails from "../../components/details-pages/users/TransactionDetails";
import { booleanValueFormatter } from "../../components/layout/TableLayout";
import { format } from "../../helpers";
import moment from "moment";

const getColor = (value) => {
	if (value === "Cancelled") {
		return "red";
	} else if (value === "Active") {
		return "green";
	}

	return "blue";
};

const columns = (props) => [
	{
		id: "Status",
		Header: "Status",
		accessor: (d) => d.status,
		Cell: (cellProps) => (
			<Label color={getColor(cellProps.original.status)}>
				{cellProps.original.status}
			</Label>
		),
		width: 100,
	},
	{
		id: "User",
		Header: "User",
		accessor: (d) => d.Name || d.UnregisteredEmail,
		highlightCell: (cellProps) =>
			cellProps.original.Name || cellProps.original.UnregisteredEmail,
		className: "bold",
	},
	{
		id: "Numberplate",
		Header: "Vehicle",
		accessor: (d) => d.Numberplate || "",
		highlightCell: (cellProps) => cellProps.original.Numberplate || "",
		width: 120,
	},
	{
		id: "SiteName",
		Header: "Sites",
		accessor: (d) => d.SiteName,
		highlightCell: (cellProps) =>
			cellProps.original.sites.map((site) => site.Name).join(", "),
	},
	{
		id: "ParkingSpace",
		Header: "Parking Space",
		accessor: (d) => d.ParkingSpace,
		highlightCell: (cellProps) => cellProps.original.LeaseParkName || "",
	},
	{
		id: "Bay",
		Header: "Bay",
		accessor: (d) => d.BayName,
		highlightCell: (cellProps) => cellProps.original.BayName || "",
	},
	{
		id: "CachedDurationType",
		Header: "Booking Type",
		accessor: (d) => d.CachedDurationType,
		highlightCell: (cellProps) => cellProps.original.CachedDurationType,
	},
	{
		id: "StartTime",
		Header: "Start Time",
		accessor: (d) => d.StartDateTime,
		highlightCell: (cellProps) => displayStartTime(cellProps.original),
	},
	{
		id: "EndTime",
		Header: "End Time",
		accessor: (d) => d.EndDateTime,
		highlightCell: (cellProps) => displayEndTime(cellProps.original),
	},
	{
		id: "Paid",
		Header: "Paid",
		accessor: (d) => d.TotalFeePaid,
		Cell: (cellProps) => format.money(cellProps.original.TotalFeePaid),
	},
	{
		id: "IsExternal",
		Header: "External",
		accessor: (d) => d.IsExternal,
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		width: 120,
	},
	{
		id: "Registered",
		Header: "Registered",
		accessor: (d) => d.registered,
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		width: 100,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenu
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="view-lease"
						onClick={() =>
							props.showUserLeaseDetails(
								cellProps.original.SiteID,
								cellProps.original.UserLeaseID,
								{
									Email: cellProps.original.Email,
									Name: cellProps.original.Name,
								}
							)
						}
					>
						More Info
					</div>,

					props.canManageLeases && cellProps.original.status === "Active" ? (
						<div
							onClick={() => {
								props.openEditBookingWizard(cellProps.original);
							}}
						>
							Edit
						</div>
					) : null,

					props.canManageLeases && cellProps.original.status === "Active" ? (
						<div
							key="cancel-lease"
							onClick={() =>
								props.openLeaseWizard(cellProps.original, "cancel-lease")
							}
						>
							Cancel
						</div>
					) : null,
				]}
			/>
		),
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const formatDateTime = (dateTime, momentFormat) =>
	moment(dateTime, momentFormat).format("DD MMM YYYY hh:mm A");
const formatDate = (dateTime, momentFormat) =>
	moment(dateTime, momentFormat).format("DD MMM YYYY");

function displayStartTime(lease) {
	if (
		lease.CachedDurationType === DURATION_TYPES.HOURS ||
		lease.CachedDurationType === DURATION_TYPES.EVENT
	) {
		return formatDateTime(lease.StartDateTime, "YYYYMMDDHHmm");
	}

	return formatDate(lease.StartDateTime, "YYYYMMDDHHmm");
}

function displayEndTime(lease) {
	if (lease.CachedDurationType === DURATION_TYPES.MONTHS) {
		return formatDate(lease.EndDateTime, "YYYYMMDDHHmm");
	}

	if (
		lease.CachedDurationType === DURATION_TYPES.HOURS ||
		lease.CachedDurationType === DURATION_TYPES.EVENT
	) {
		return lease.IsCancelled
			? formatDateTime(lease.CancelledOn, "X")
			: formatDateTime(
					moment(lease.EndDateTime, "YYYYMMDDHHmm").add(1, "minutes"),
					"YYYYMMDDHHmm"
			  );
	}

	if (lease.CachedDurationType === DURATION_TYPES.DAYS) {
		return lease.IsCancelled
			? formatDate(lease.CancelledOn, "X")
			: formatDate(lease.EndDateTime, "YYYYMMDDHHmm");
	}

	return null;
}

const defaultSorting = [
	{
		id: "StartDate",
		desc: true,
	},
];

export default function BookingsContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			"lease-parks": [],
		},
		search: "",
		leaseFilter: "active",
		selectedSiteId: null,
		selectedUserLeaseId: null,
		selectedUserLeaseUser: null,
		reportStyleOverride: {},
		leaseAdminWizardOpen: false,
		manipulated: false,
		editBookingWizardOpen: false,
	});

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const canManageLeases = usePermissions(null, "ManageLeases", true);
	const canViewOrganization = useHasOrganizationViewership();

	async function updateOptions(
		{ search, ...options },
		parkingBlocksManipulated
	) {
		//If this method was called by the ReportWrapper, it won't have the notion
		//of parking blocks, so insert it back into the update object before applying it
		//over the current state
		if (!options["lease-parks"]) {
			options["lease-parks"] = state.options["lease-parks"]
				? state.options["lease-parks"]
				: [];
		}

		if (parkingBlocksManipulated) {
			setState((_state) => ({
				..._state,
				initialLoad: false,
				search,
				options,
				manipulated: true,
			}));
		} else {
			setState((_state) => ({
				..._state,
				initialLoad: false,
				search,
				options,
			}));
		}
	}

	//Retrieve core repeating dat
	const { data, isLoading } = useFetchData(
		[],
		installationApi.getUserLeaseReport,
		!state.initialLoad ? [organizationId, state.options] : null,
		[organizationId, ...Object.values(state.options)]
	);

	const { data: leaseParks, isLoading: isLoadingParkingBlocks } = useFetchData(
		[],
		installationApi.getLeaseParksForOrganization,
		[organizationId],
		[organizationId]
	);

	//Filter the stat block values
	let filteredLeaseParkIds =
		state.options["lease-parks"].length > 0
			? state.options["lease-parks"]
			: leaseParks.map((leasePark) => leasePark.LeaseParkID);
	const filteredSiteIds =
		state.options["sites"].length > 0
			? state.options["sites"]
			: props.availableSites.map((site) => site.SiteID);
	let stats = {
		totalBookings: 0,
		totalMonthlySpaces: 0,
		remainingSpaces: 0,
		totalBookedSpaces: 0,
	};

	if (leaseParks && leaseParks.length) {
		stats.totalMonthlySpaces = leaseParks.reduce((sum, leasePark) => {
			if (filteredLeaseParkIds.indexOf(leasePark.LeaseParkID) == -1) {
				return sum;
			}

			//Make sure the lease park has at least one of the sites being filtered for
			const siteIds = leasePark.sites.map((site) => site.SiteID);
			const overlap = intersection(filteredSiteIds, siteIds);
			if (overlap.length === 0) {
				return sum;
			}

			sum += leasePark.Spaces;
			return sum;
		}, 0);
	}

	if (data && data.length && leaseParks && leaseParks.length) {
		stats.totalBookings = data.reduce((sum) => {
			sum++;
			return sum;
		}, 0);

		data.forEach((booking) => {
			if (!booking.SpaceReleased && !booking.isExpired) {
				stats.totalBookedSpaces += 1;
			}
		});
	}

	stats.remainingSpaces = stats.totalMonthlySpaces - stats.totalBookedSpaces;

	let filteredLeases = useSearchFilter(data || [], state.search, [
		"User",
		"Numberplate",
		"ParkingSpace",
		"_startDate",
		"_endDate",
		"BayName",
	]);

	filteredLeases = filteredLeases.filter((e) => {
		if (state.leaseFilter === "all") return true;
		if (state.leaseFilter === "active") return e.isActive;
		if (state.leaseFilter === "ended") return !e.isActive;
	});

	function openLeaseWizard(lease, mode) {
		setState((_state) => ({
			..._state,
			leaseAdminWizardOpen: true,
			leaseAdminWizardLease: lease,
			leaseAdminWizardMode: mode,
		}));
	}

	function openEditBookingWizard(lease) {
		setState((_state) => ({
			..._state,
			editBookingWizardLease: lease,
			editBookingWizardOpen: true,
		}));
	}

	function showUserLeaseDetails(siteId, userLeaseId, user) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedUserLeaseId: userLeaseId,
			selectedUserLeaseUser: user,
			reportStyleOverride: { display: "none" },
		}));
	}

	function hideUserLeaseDetails() {
		setState((_state) => ({
			..._state,
			selectedSiteId: null,
			selectedUserLeaseId: null,
			selectedUserLeaseUser: null,
			reportStyleOverride: {},
		}));
	}

	if (state.leaseAdminWizardOpen) {
		return (
			<LeaseWizard
				close={() => {
					setState((_state) => ({ ..._state, leaseAdminWizardOpen: false }));
				}}
				organizationId={organizationId}
				mode={state.leaseAdminWizardMode}
				lease={state.leaseAdminWizardLease}
				spaces={leaseParks}
			/>
		);
	}

	async function onLeaseFilterChange(leaseFilter) {
		setState((_state) => ({ ..._state, leaseFilter }));
	}

	/**
	 * Whenever the parking block filter dropdown is changed, append the change
	 * to the state.options data and call the parent handler
	 *
	 * @param {integer[]} parkingBlockFilter Array of IDs to filter parking blocks by
	 */
	async function parkingBlockSelectionChanged(parkingBlockFilter) {
		const options = state.options;
		options["lease-parks"] = parkingBlockFilter;
		updateOptions(options, true);
	}

	if (state.editBookingWizardOpen) {
		return (
			<EditBookingWizard
				close={() => {
					setState((_state) => ({ ..._state, editBookingWizardOpen: false }));
				}}
				lease={state.editBookingWizardLease}
			/>
		);
	}

	return (
		<div>
			<ReportWrapper
				{...props}
				title="Bookings"
				style={state.reportStyleOverride}
				data={filteredLeases}
				availableSites={filter(
					props.availableSites,
					(site) => site.HasLeaseParking
				)}
				columns={columns({
					searchFilter: state.searchFilter,
					openLeaseWizard: openLeaseWizard,
					showUserLeaseDetails: showUserLeaseDetails,
					canManageLeases: canManageLeases,
					openEditBookingWizard: openEditBookingWizard,
				})}
				defaultSorted={defaultSorting}
				showSearchBox={false}
				updateOptions={updateOptions}
				loading={isLoading && isLoadingParkingBlocks}
				leftActions={
					<ReportPicker
						style={{ marginLeft: 16 }}
						name="parking spaces"
						options={leaseParks.map((leasePark) => {
							return { value: leasePark.LeaseParkID, label: leasePark.Name };
						})}
						selected={
							state.manipulated
								? state.options["lease-parks"]
								: leaseParks.map((leasePark) => leasePark.LeaseParkID)
						}
						onSelectedChanged={parkingBlockSelectionChanged}
					/>
				}
				rightActions={
					canViewOrganization ? (
						<Button
							color="blue"
							onClick={() => openLeaseWizard(null, "add-external-booking")}
						>
							<UserPlus size={20} /> Add External Booking
						</Button>
					) : null
				}
				stats={
					<div>
						<FlexWrapper style={{ marginBottom: -16 }}>
							<Card style={{ flex: 0.3 }}>
								<StatCard
									value={stats.totalBookings}
									title="Total Bookings"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>

							<Card style={{ flex: 0.3 }}>
								<StatCard
									value={stats.totalMonthlySpaces}
									title="Total Booking Spaces"
									size="medium"
								/>
							</Card>

							<Card style={{ flex: 0.3 }}>
								<StatCard
									value={stats.totalBookedSpaces}
									title="Total Booked Spaces"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>

							<Card style={{ flex: 0.3 }}>
								<StatCard
									value={stats.remainingSpaces}
									title="Remaining Booking Spaces"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
						</FlexWrapper>
						<div style={{ margin: 32 }}>
							<TabSelector
								items={[
									{ value: "all", label: "All" },
									{ value: "active", label: "Active" },
									{ value: "ended", label: "Ended" },
								]}
								value={state.leaseFilter}
								onChange={onLeaseFilterChange}
							/>
						</div>
					</div>
				}
			/>
			{state.selectedUserLeaseId && (
				<TransactionDetails
					{...props}
					siteId={state.selectedSiteId}
					lease={{ UserLeaseID: state.selectedUserLeaseId }}
					user={state.selectedUserLeaseUser}
					onClose={hideUserLeaseDetails}
				/>
			)}
		</div>
	);
}
