import { MoreHorizontal, Plus, Upload } from "react-feather";
import React, { useContext, useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import {
	useQueryData,
	useFetchData,
	useCanManageValidations,
} from "../../../hooks";
import { getLeaseParksForOrganization } from "../../../api/installation";
import { AppContext } from "../../../context/app-context";
import Button from "../../layout/Button";
import DropdownMenuBeta from "../../../components/layout/DropdownMenuBeta";
import ImportPromotionsWizard from "../../wizards/promotions-wizard/ImportPromotionsWizard";
import PromotionsWizard from "../../wizards/promotions-wizard";
import ReportWrapperBeta from "../ReportWrapperBeta";
import gql from "graphql-tag";
import moment from "moment";

const defaultSorting = [
	{
		id: "Name",
		desc: false,
	},
];

export const useGetAllPromotionsForOrganization = (
	organizationId,
	siteList,
	paginate,
	skipQuery = false
) => {
	const {
		data,
		loading: promotionsLoading,
		isError,
		refetch,
	} = useQueryData(
		gql`
			query GetAllPromotionsForOrganization(
				$organizationId: Int!
				$siteList: [Int!]
				$page: PageInfo!
				$cursor: Int
			) {
				getAllPromotionsForOrganization(
					organizationId: $organizationId
					siteList: $siteList
					page: $page
					cursor: $cursor
				) {
					trueLength
					previousEvent
					promotions {
						PromotionID
						Name
						PromotionCode
						PromotionType
						PromotionValue
						StartTimestamp
						EndTimestamp
						EndDateFormatted
						StartDateFormatted
						OnAllSites
						Status
						UserLimit
						CodeLimit
						Sites {
							Name
							SiteID
						}
						Rates {
							Name
							RateID
							SiteID
							SiteName
						}
						LeaseParks {
							LeaseParkID
							Name
						}
					}
				}
			}
		`,
		{
			organizationId,
			siteList: siteList || [],
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	const promotions = data ? data.getAllPromotionsForOrganization : null;

	return { promotions, promotionsLoading, isError, refetch };
};

export default function ValidationPromotions(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			search: "",
		},
		promotion: "",
		wizardOpen: false,
		importPromotionsWizardOpen: false,
		submitting: false,
	});

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	const {
		state: { availableSites },
	} = useContext(AppContext);

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const promotionTypeOptions = [
		{ value: "Fixed", label: "Fixed Amount" },
		{ value: "Percentage", label: "Percentage Amount" },
		// { value: "Rate", label: "Promotion Rate" },
		{ value: "DurationDays", label: "Duration Days" },
		{ value: "DurationHours", label: "Duration Hours" },
		{ value: "Rate", label: "Validation Rate" },
	];

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = (mode, promotion) => {
		setState((_state) => ({
			..._state,
			promotion,
			wizardOpen: true,
			wizardMode: mode,
		}));
	};

	const canEditSettings = useCanManageValidations();

	const { data: leaseParks, isLoading: spacesIsLoading } = useFetchData(
		[],
		getLeaseParksForOrganization,
		[organizationId],
		[organizationId]
	);

	const columns = [
		{
			id: "Status",
			Header: "Status",
			accessor: (d) => d.Status,
			highlightCell: (cellProps) => cellProps.row.original.Status || "",
		},
		{
			id: "Name",
			Header: "Name",
			accessor: (d) => d.Name,
			highlightCell: (cellProps) => cellProps.row.original.Name || "",
		},
		{
			id: "PromotionCode",
			Header: "Promotion Code",
			accessor: (d) => d.PromotionCode,
			highlightCell: (cellProps) => cellProps.row.original.PromotionCode || "",
		},
		{
			id: "PromotionType",
			Header: "Type",
			accessor: (d) => d.PromotionType,
			highlightCell: (cellProps) => cellProps.row.original.PromotionType || "",
		},
		{
			id: "ValueFormatted",
			Header: "Rate",
			accessor: (d) => d.ValueFormatted,
			highlightCell: (cellProps) => cellProps.row.original.ValueFormatted || "",
			disableSortBy: true,
		},
		{
			id: "UserLimit",
			Header: "User Limit",
			accessor: (d) => d.UserLimit,
			highlightCell: (cellProps) =>
				cellProps.row.original.UserLimit || "No limit",
		},
		{
			id: "CodeLimit",
			Header: "Code Limit",
			accessor: (d) => d.CodeLimit,
			highlightCell: (cellProps) =>
				cellProps.row.original.CodeLimit || "No limit",
		},
		{
			id: "Availability",
			Header: "Availability",
			accessor: (d) => d._availableDates,
			highlightCell: (cellProps) =>
				cellProps.row.original._availableDates || "",
			disableSortBy: true,
		},
		{
			id: "SiteNames",
			Header: "Site(s)",
			accessor: (d) => d.SiteNames,
			highlightCell: (cellProps) => cellProps.row.original.SiteNames || "",
			disableSortBy: true,
		},
		{
			id: "Spaces",
			Header: "Space(s)",
			accessor: (d) => d.LeaseParks.map((lp) => lp.Name).join(", ") ?? "",
			disableSortBy: true,
		},
	];

	const canManagePromotions = (_props) => [
		...columns,
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => {
				const toggleLabel =
					cellProps.row.original.Status === "Active" &&
					cellProps.row.original.Status !== "Expired"
						? "disable"
						: "enable";
				return (
					<DropdownMenuBeta
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="update"
								onClick={() =>
									_props.openWizard("update", cellProps.row.original)
								}
							>
								Edit
							</div>,
							<div
								key={toggleLabel}
								onClick={() =>
									_props.openWizard(toggleLabel, cellProps.row.original)
								}
							>
								{toggleLabel.charAt(0).toUpperCase() + toggleLabel.slice(1)}
							</div>,
							<div
								key="delete"
								onClick={() =>
									_props.openWizard("delete", cellProps.row.original)
								}
							>
								Delete
							</div>,
						]}
					/>
				);
			},
			resizable: false,
			width: 50,
		},
	];

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const {
		promotions: getAllPromotionsForOrganization,
		promotionsLoading,
		refetch,
	} = useGetAllPromotionsForOrganization(
		organizationId,
		state.options.sites || [],
		paginate,
		skipQuery
	);

	let promotions = [];
	let trueLength = 0;
	if (
		getAllPromotionsForOrganization &&
		getAllPromotionsForOrganization.promotions
	) {
		// Formatting Data
		trueLength = getAllPromotionsForOrganization?.trueLength;
		promotions = getAllPromotionsForOrganization.promotions.map((promotion) => {
			let _availableDates = "";

			if (!promotion.StartTimestamp & !promotion.EndTimestamp) {
				_availableDates = "Any time";
			} else {
				let startDate = promotion.StartTimestamp
					? `From ${moment(promotion.StartTimestamp, "X").format(
							"DD MMM YYYY"
					  )}`
					: "";
				let endDate = promotion.EndTimestamp
					? `To ${moment(promotion.EndTimestamp, "X").format("DD MMM YYYY")}`
					: "";

				_availableDates = `${startDate} ${endDate}`;
			}

			let promotionListValue;
			switch (promotion.PromotionType) {
				case "DurationDays":
				case "DurationHours":
					promotionListValue =
						promotion.PromotionType === "DurationDays"
							? `${promotion.PromotionValue} days free`
							: `${promotion.PromotionValue} hours free`;
					break;
				case "Rate":
					promotionListValue = promotion.Rates.map((rate) => rate.Name).join(
						", "
					);
					break;
				default:
					promotionListValue =
						promotion.PromotionType === "Fixed"
							? `-$${promotion.PromotionValue}`
							: `-${promotion.PromotionValue}%`;
					break;
			}

			return {
				...promotion,
				StartDate: promotion.StartTimestamp
					? moment.unix(promotion.StartTimestamp).format("DD MMM YYYY")
					: null,
				EndDate: promotion.EndTimestamp
					? moment.unix(promotion.EndTimestamp).format("DD MMM YYYY")
					: null,
				_availableDates: _availableDates,
				ValueFormatted: promotionListValue,
				SiteNames: promotion.OnAllSites
					? "All Sites"
					: promotion.Sites?.map((site) => {
							return site.Name;
					  }).join(", "),
			};
		});
	}

	async function refetchData() {
		await refetch();
		setState((_state) => ({ ..._state, submitting: false }));
	}

	if (state.wizardOpen) {
		return (
			<PromotionsWizard
				type="promotion"
				mode={state.wizardMode}
				organizationId={organizationId}
				promotionTypeOptions={promotionTypeOptions}
				availableSites={availableSites.map((site) => ({
					id: site.SiteID,
					name: site.Name,
				}))}
				close={() => {
					setState((_state) => ({
						..._state,
						reloadDataToggle: !state.reloadDataToggle,
						wizardOpen: false,
						submitting: true,
					}));

					refetchData();
				}}
				promotion={state.promotion}
				availableSpaces={availableSites.map((site) => ({
					SiteID: site.SiteID,
					Spaces: leaseParks
						.filter((lp) => lp.sites.some((s) => s.SiteID === site.SiteID))
						.map((lp) => ({ LeaseParkID: lp.LeaseParkID, Name: lp.Name })),
				}))}
			/>
		);
	}

	if (state.importPromotionsWizardOpen) {
		return (
			<ImportPromotionsWizard
				close={() => {
					setState((_state) => ({
						..._state,
						importPromotionsWizardOpen: false,
					}));

					refetchData();
				}}
				mode={state.wizardMode}
				selectedOrganization={organizationId}
				availableSites={availableSites.map((site) => ({
					id: site.SiteID,
					name: site.Name,
				}))}
			/>
		);
	}

	return (
		<ReportWrapperBeta
			{...props}
			title="Promotions"
			data={promotions}
			columns={canEditSettings ? canManagePromotions({ openWizard }) : columns}
			defaultSortBy={defaultSorting}
			updateOptions={updateOptions}
			loading={state.submitting}
			rightActions={
				canEditSettings ? (
					<>
						<Button key="submit" color="blue" onClick={() => openWizard("add")}>
							<Plus size={20} /> Add Promotion
						</Button>
						<Button
							style={{ marginLeft: 16 }}
							color="blue"
							onClick={() =>
								setState((_state) => ({
									..._state,
									importPromotionsWizardOpen: true,
								}))
							}
						>
							<Upload size={20} /> Import Promotions
						</Button>
					</>
				) : null
			}
			dataTrueLength={trueLength}
			cursorColumn="CreatedOn"
			paginationIsLoading={
				promotionsLoading || skipQuery || props.isLoading || spacesIsLoading
			}
			pageSize={state.pageSize}
			previousEvent={getAllPromotionsForOrganization?.previousEvent}
			searchTokens={paginate.searchTokens}
			showDateRangePicker={false}
			fetchPaginatedData={fetchPaginatedData}
		/>
	);
}
