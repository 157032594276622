import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import React, { useState } from "react";
import { cloneDeep, keys } from "lodash";
import {
	useHasClientAdmin,
	useMutateData,
	usePermissions,
	useQueryData,
} from "../../hooks";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import EditableInputField from "../../components/layout/EditableInputField";
import ErrorBoundary from "../ErrorBoundary";
import FormLayout from "../../components/layout/FormLayout";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import ModalDialog from "../../components/layout/ModalDialog";
import PageTitle from "../../components/layout/PageTitle";
import gql from "graphql-tag";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { constants } from "../../helpers";
import Repeater from "../../components/layout/Repeater";
import Input from "../../components/layout/Input";
import Toggle from "../../components/layout/Toggle";

const FieldWrapper = styled.div`
	margin: 16px 32px;

	> * {
		margin: 0 10px;
	}
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

export default function SettingsContainer(props) {
	const { t: translate } = useTranslation();

	const [state, setState] = useState({
		reloadToggle: false,
		deleteConfirmationShow: false,
		deletingOrganization: false,
	});

	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		props.selectedOrganization?.ClientID
	);

	if (!(isAdmin || hasClientAdmin)) {
		props.history.push("/");
	}

	const {
		data: { getClients },
		isLoading,
	} = useQueryData(
		gql`
			query {
				getClients {
					ClientID
					Name
				}
			}
		`,
		{ organizationId: props.organizationId },
		!isAdmin
	);

	const mutation = useMutateData(gql`
		mutation (
			$organizationId: Int!
			$name: String
			$companyId: String
			$clientId: Int
			$operatorId: Int
			$defaultCurrency: String
			$defaultTimezone: String
			$defaultTaxRates: [DefaultTaxRateInput]
			$hasEnforcement: Boolean!
			$hasOccupancy: Boolean
			$hasEventManagement: Boolean
			$hasGateAccessQRCodes: Boolean
		) {
			updateOrganization(
				organizationId: $organizationId
				name: $name
				companyId: $companyId
				clientId: $clientId
				operatorId: $operatorId
				defaultCurrency: $defaultCurrency
				defaultTimezone: $defaultTimezone
				defaultTaxRates: $defaultTaxRates
				hasEnforcement: $hasEnforcement
				hasOccupancy: $hasOccupancy
				hasEventManagement: $hasEventManagement
				hasGateAccessQRCodes: $hasGateAccessQRCodes
			)
		}
	`);

	const settingsSchema = Yup.object().shape({
		Name: Yup.string().required(),
		ClientID: Yup.number().integer().nullable(),
	});

	const initialValues = cloneDeep({
		Name: props.selectedOrganization?.Name,
		ClientID: props.selectedOrganization?.ClientID,
		CompanyID: props.selectedOrganization?.CompanyID,
		OperatorID: props.selectedOrganization?.OperatorID,
		DefaultCurrency: props.selectedOrganization?.DefaultCurrency,
		DefaultTimezone: props.selectedOrganization?.DefaultTimezone,
		DefaultTaxRates: props.selectedOrganization?.DefaultTaxRates?.map(
			(defaultTaxRate) => ({
				Name: defaultTaxRate.Name,
				Percentage: defaultTaxRate.Percentage,
			})
		) || [{}],
		HasEnforcement: props.selectedOrganization?.HasEnforcement,
		HasOccupancy: props.selectedOrganization?.HasOccupancy,
		HasEventManagement: props.selectedOrganization?.HasEventManagement,
		HasGateAccessQRCodes: props.selectedOrganization?.HasGateAccessQRCodes,
	});

	const clientOptions = (getClients || []).map((client) => ({
		value: client.ClientID,
		label: client.Name,
	}));

	const operatorOptions = (props.availableOperators || []).map((operator) => ({
		value: operator.OperatorID,
		label: operator.Name,
	}));

	const timezoneOptions = constants.TIME_ZONES;
	const currencyOptions = constants.CURRENCIES;
	const taxTypeOptions = constants.TAX_TYPES;

	async function deleteOrganization(organizationId) {
		try {
			setState((_state) => ({ ..._state, deletingOrganization: true }));
			await installationApi.deleteOrganizationById(organizationId);
			Alert.success("Account deleted.");

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			setState((_state) => ({ ..._state, deletingOrganization: false }));
			Alert.error(translate("Errors.Generic"));
		}
	}

	function confirmationText() {
		return (
			<div>
				Are you sure you want to DELETE this account
				<div style={{ fontWeight: "bold" }}>{initialValues.Name}?</div>
				This action CANNOT be undone.
			</div>
		);
	}

	return (
		<ErrorBoundary>
			<ModalDialog
				isOpen={state.deleteConfirmationShow}
				close={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				dialogText={confirmationText()}
				leftButtonText="Cancel"
				leftButtonColor="blue"
				leftButtonDisabled={state.deletingOrganization}
				onLeftButtonClick={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				rightButtonText="Delete"
				rightButtonColor="red"
				rightButtonDisabled={state.deletingOrganization}
				onRightButtonClick={() => {
					deleteOrganization(props.selectedOrganization.OrganizationID);
				}}
			/>
			<PageTitle>Client</PageTitle>
			<FormLayout
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={settingsSchema}
				onSubmit={async (values) => {
					try {
						await mutation({
							variables: {
								organizationId: props.selectedOrganization.OrganizationID,
								name: values.Name,
								companyId: values.CompanyID,
								clientId: values.ClientID,
								operatorId: values.OperatorID,
								defaultCurrency: values.DefaultCurrency,
								defaultTimezone: values.DefaultTimezone,
								defaultTaxRates: values.DefaultTaxRates,
								hasEnforcement: values.HasEnforcement,
								hasOccupancy: values.HasOccupancy,
								hasEventManagement: values.HasEventManagement,
								hasGateAccessQRCodes: values.HasGateAccessQRCodes,
							},
						});

						setState((_state) => ({
							..._state,
							reloadToggle: !state.reloadToggle,
						}));

						props.getAvailableOrganizations();

						props.history.push(
							`/operator/${values.OperatorID}/organization/${props.selectedOrganization.OrganizationID}/settings`
						);

						Alert.success("Account settings updated");
					} catch (error) {
						Alert.error(translate("Errors.Generic"));
					}
				}}
				render={({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => {
					if (isLoading) {
						return <LoadingPlaceholder />;
					}

					return (
						<form
							className="form"
							onSubmit={(event) => {
								event.preventDefault();
							}}
						>
							<Card>
								<Accordion title="Client Info" expanded={true}>
									<FieldWrapper>
										<Label>Name</Label>
										<FieldContent>
											<EditableInputField
												name="Name"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.Name || ""}
												error={errors.Name && touched.Name ? errors.Name : null}
											/>
										</FieldContent>
									</FieldWrapper>

									<FieldWrapper>
										<Label>External Client ID</Label>
										<FieldContent>
											<EditableInputField
												name="CompanyID"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.CompanyID || ""}
												error={
													errors.CompanyID && touched.CompanyID
														? errors.CompanyID
														: null
												}
											/>
										</FieldContent>
									</FieldWrapper>
								</Accordion>
							</Card>

							{isAdmin && (
								<Card>
									<Accordion title="Client Defaults" expanded={true}>
										<FieldWrapper>
											<Label>Timezone</Label>
											<FieldContent style={{ marginLeft: "0" }}>
												<Dropdown
													isClearable={true}
													onChange={(value) =>
														setFieldValue(
															"DefaultTimezone",
															value ? value.value : null
														)
													}
													options={timezoneOptions}
													value={timezoneOptions.find(
														(e) => e.value === values.DefaultTimezone
													)}
												/>
											</FieldContent>
										</FieldWrapper>
										<FieldWrapper>
											<Label>Currency</Label>
											<FieldContent style={{ marginLeft: "0" }}>
												<Dropdown
													isClearable={true}
													onChange={(value) =>
														setFieldValue(
															"DefaultCurrency",
															value ? value.value : null
														)
													}
													options={currencyOptions}
													value={currencyOptions.find(
														(e) => e.value === values.DefaultCurrency
													)}
												/>
											</FieldContent>
										</FieldWrapper>
										<FieldWrapper>
											<Label>Tax Rates</Label>
											<Repeater
												style={{ width: "100%", marginLeft: "0" }}
												items={values.DefaultTaxRates || [{}]}
												disableAdd={values.DefaultTaxRates.length >= 4}
												add={() => {
													const items = values.DefaultTaxRates
														? [...values.DefaultTaxRates]
														: [];
													items.push({});
													setFieldValue("DefaultTaxRates", items);
												}}
												subtract={() => {
													const items = [...values.DefaultTaxRates];
													items.pop();
													setFieldValue("DefaultTaxRates", items);
												}}
												template={(value, index) => {
													return (
														<div style={{ display: "flex", width: "90%" }}>
															<Dropdown
																style={{ width: "70%", marginRight: "20px" }}
																options={taxTypeOptions.filter(
																	(dl) =>
																		!values.DefaultTaxRates?.map(
																			(dtr) => dtr.Name
																		).includes(dl.value)
																)}
																value={taxTypeOptions.find(
																	(dl) => dl.value === value.Name
																)}
																onChange={(event) => {
																	const items = [...values.DefaultTaxRates];
																	items[index].Name = event.value;
																	setFieldValue("DefaultTaxRates", items);

																	if (event.value === "Zero Rated") {
																		items[index].Percentage = 0;
																		setFieldValue("DefaultTaxRates", items);
																	}
																}}
															/>
															<div style={{ display: "flex", width: "100%" }}>
																<Input
																	type="number"
																	min="0"
																	max="100"
																	value={value.Percentage}
																	onChange={(event) => {
																		const items = [...values.DefaultTaxRates];
																		let val = event.target.value;

																		if (val > 100) {
																			val = 100;
																		} else if (val < 0) {
																			val = 0;
																		}

																		items[index].Percentage = parseInt(val);
																		setFieldValue("DefaultTaxRates", items);
																	}}
																	disabled={value.Name === "Zero Rated"}
																/>
															</div>
														</div>
													);
												}}
											/>
										</FieldWrapper>

										{isAdmin && (
											<>
												<FieldWrapper>
													<div
														style={{
															display: "flex",
															alignItems: "center",
														}}
													>
														<Label
															style={{ marginBottom: "0px", width: "7rem" }}
														>
															Enforcement
														</Label>
														<FieldContent style={{ marginLeft: "0" }}>
															<Toggle
																checked={values.HasEnforcement}
																onChange={(hasEnforcement) => {
																	setFieldValue(
																		"HasEnforcement",
																		hasEnforcement
																	);
																}}
															/>
														</FieldContent>
													</div>
												</FieldWrapper>
												<FieldWrapper>
													<div
														style={{
															display: "flex",
															alignItems: "center",
														}}
													>
														<Label
															style={{ marginBottom: "0px", width: "7rem" }}
														>
															Occupancy
														</Label>
														<FieldContent style={{ marginLeft: "0" }}>
															<Toggle
																checked={values.HasOccupancy}
																onChange={(hasOccupancy) => {
																	setFieldValue("HasOccupancy", hasOccupancy);
																}}
															/>
														</FieldContent>
													</div>
												</FieldWrapper>
												<FieldWrapper>
													<div
														style={{
															display: "flex",
															alignItems: "center",
														}}
													>
														<Label
															style={{ marginBottom: "0px", width: "7rem" }}
														>
															Event Management
														</Label>
														<FieldContent style={{ marginLeft: "0" }}>
															<Toggle
																checked={values.HasEventManagement}
																onChange={(hasEventManagement) => {
																	setFieldValue(
																		"HasEventManagement",
																		hasEventManagement
																	);
																}}
															/>
														</FieldContent>
													</div>
												</FieldWrapper>
												<FieldWrapper>
													<div
														style={{
															display: "flex",
															alignItems: "center",
														}}
													>
														<Label
															style={{ marginBottom: "0px", width: "7rem" }}
														>
															Gate Access QR Codes
														</Label>
														<FieldContent style={{ marginLeft: "0" }}>
															<Toggle
																checked={values.HasGateAccessQRCodes}
																onChange={(hasGateAccessQRCodes) => {
																	setFieldValue(
																		"HasGateAccessQRCodes",
																		hasGateAccessQRCodes
																	);
																}}
															/>
														</FieldContent>
													</div>
												</FieldWrapper>
											</>
										)}
									</Accordion>
								</Card>
							)}

							{isAdmin && (
								<Card>
									<Accordion title="Account" expanded={true}>
										<FieldWrapper>
											<FieldContent style={{ marginLeft: "0" }}>
												<Dropdown
													isClearable={true}
													onChange={(value) =>
														setFieldValue(
															"ClientID",
															value ? value.value : null
														)
													}
													options={clientOptions}
													value={clientOptions.find(
														(e) => e.value === values.ClientID
													)}
												/>
											</FieldContent>
										</FieldWrapper>
									</Accordion>
								</Card>
							)}

							{isAdmin && (
								<Card>
									<Accordion title="Operator" expanded={true}>
										<FieldWrapper>
											<FieldContent style={{ marginLeft: "0" }}>
												<Dropdown
													isClearable={true}
													onChange={(value) =>
														setFieldValue(
															"OperatorID",
															value ? value.value : null
														)
													}
													options={operatorOptions}
													value={operatorOptions.find(
														(e) => e.value === values.OperatorID
													)}
												/>
											</FieldContent>
										</FieldWrapper>
									</Accordion>
								</Card>
							)}

							<div>
								<Button
									color="red"
									onClick={() => {
										setState((_state) => ({
											..._state,
											deleteConfirmationShow: true,
										}));
									}}
									disabled={isSubmitting || keys(errors).length > 0}
								>
									Delete Client
								</Button>
								<Button
									style={{ float: "right" }}
									color="green"
									onClick={handleSubmit}
									disabled={isSubmitting || keys(errors).length > 0}
								>
									Update Client
								</Button>
							</div>
						</form>
					);
				}}
			/>
		</ErrorBoundary>
	);
}
